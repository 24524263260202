import React from "react";
import { useState } from "react";
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
import { fileToByteString } from "../../../utilities/FileHandler";
import { userState } from "../../../state";
import { useAtom } from "jotai";
import { ticketsState } from "../../../state";
import TicketDetailsConversation from "./TicketDetailsConversation";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { toast } from "react-toastify";

const defaulValues = {
  message: "",
  status: -9
}

function AdminTicketDetails({resetState}) {
  const axiosPrivate = useAxiosPrivate();
  const naviagate = useNavigate();

  const [toggleMessageBox, setToggleMessageBox] = useState(true);
  const [fileName, setFileName] = useState("");
  const [fileByteString, setFileByteString] = useState("");
  const [statuses, setStatuses] = useState([]);

  const [user] = useAtom(userState.user);

  const [currentTicketData] = useAtom(ticketsState.currentTicketData);
  const [currentTicketConversation] = useAtom(ticketsState.currentTicketConversation);

  const [formFields, setFormFields] = useState(defaulValues);

  const { message, status } = formFields;

  const handleChange = (e) => {
    const { name, value } = e.target;
    console.log(formFields);
    setFormFields({ ...formFields, [name]: value });
  };

  const imageToByteString = async () => {
    const fileName = document.getElementById("file-upload").files[0].name;
    let file = document.getElementById("file-upload").files[0];
    if (file.size > 5242880) {
      toast.warn("File size larger than 5 mb", { position: "top-center" });
      file = ""
      return;
    }
    const response = await fileToByteString(file);
    console.log(response);
    setFileName(fileName);
    setFileByteString(response);
  };


  const sendMessage = async() => {
    // trimit mesaul si fisierul spre backend in tichete activitati
    const ticketActivity = {
      idTichet: currentTicketData.idTichet,
      atasament: fileByteString,
      continut: message,
      numeFisier: fileName,
      idUser: user.id
    }

    try {
      const response = await axiosPrivate.post("/ticket-conversation", { ticketActivity, ticketStatus: status === -9 ?  currentTicketData.status : status })
      console.log(response.data);
    } catch (e) {
      console.log(e)
    } finally {
      // <TicketResetState />
      resetState();
      // naviagate("/");
    }
  }

  const getStatuses = async() => {
    try {
      const response = await axiosPrivate.get("ticket-statuses");
      setStatuses(response.data);
    } catch (e) {
      console.log(e)
    }
  }

  useEffect(() => {
    getStatuses();
  }, [])

  return (
    <div style={{maxHeight: "800px"}} className='overflow-auto'>
      <div className="font-bold flex gap-6">
        <div>
          <p>Nr ticket</p>
          <div className='bg-lightGray p-2 pr-4 text-gray'>{currentTicketData.idTichet}</div>
        </div>
        <div>
          <p>Categorie</p>
          <div className='bg-lightGray p-2 pr-4 text-gray'>{currentTicketData.categorie}</div>
        </div>
       
        <div className="text-center">
          <p>Adauga raspuns</p>
          <p style={{fontSize: "50px"}} className="material-icons cursor-pointer" onClick={() => setToggleMessageBox(!toggleMessageBox)}>add_box</p>
        </div>
        {/* <div className="text-center">
          <p>Inchide ticket</p>
           TODO controll the input 
          <input style={{width: "39px", height: "39px"}} type="checkbox" name="closeTicket" id="closeTicket" />
        </div> */}
        <div>
          <p>Status</p>
          <div className='bg-lightGray p-2 pr-4 text-gray'>{currentTicketData.status}</div>
        </div>
        <div>
          <p>Seteaza status</p>
          {/* <div className='bg-lightGray p-2 pr-4 text-gray'>{currentTicketData.status}</div> */}
          <select name="status" id="status" className="border h-10" value={status} onChange={handleChange}>
             <option value={-9} disabled>Selecteaza noul status</option>
            {statuses.map(stat => {
              return (
                <option value={stat.idStatus} disabled={stat.numeStatus === currentTicketData.status} >{stat.numeStatus}</option>
              )
            })}
          </select>
        </div>
        <div className="mt-6">
          <div className='bg-primary2 text-white p-2 pr-8 cursor-pointer h-10' onClick={sendMessage}>Salveaza</div>
        </div>
      </div>
       <div>
          <p>Subiect</p>
          <div className='bg-lightGray p-2 pr-4 max-w-md h-10 overflow-auto text-gray'>{currentTicketData.subiect}</div>
        </div>

      <div>
        
        {toggleMessageBox &&
          <div>
            <textarea onChange={handleChange} className="border-2 mt-4" name="message" value={message} id="message" cols="60" rows="5"></textarea>
            <br />
            <span className="material-icons mt-2 md-48 cursor-pointer" onClick={() => document.getElementById("file-upload").click()}>attach_file</span>
            <input className="hidden" type="file" accept="image/png, image/jpeg, image/jpg," name="" id="file-upload" onChange={imageToByteString} />
        </div>
        }
        
      </div>
      <TicketDetailsConversation />
    </div>
  );
}


//pt mine:
  //  Cand da click pe un tichet, fac un toggle ca sa se incarce in TicketsPage viewul detailat pt tichet
  //in functie de ce tip de user e, admin sau nu
  //  Tot pe click pe ticket ma duc si iau detaliile tichetului si populez pagina (pot sa bag astea in atom sa fie mai usor)

// pt securitate as putea sa fac o metoda in genul hasAccesToCompany care verifica daca compania are acces la tichet (id_tichet si id_companie pe tabela tichete)

// in view activiati:

  //fieldurile sunt gresit, sunt incurcate !!!

  // sa imi aduca tipul de user (admin sau nu)  |optional, pot sa il iau eu din user.tipUser daca imi bag sa il aduc din controller|
  // status (deschis inchis etc)
  // id ticket
  // categorie
  // SA NU aduca tot fisierul, fac eu un query sa il aduc dupa id activitate mai bine

// in view tichete:
  // nu aduce ce trebuie, am nevoie sa vad doar ce se vede si in poza de pe trello la overview





export default AdminTicketDetails;
