import React, {Fragment, useEffect, useState}   from 'react';
import {useAtom}                                from 'jotai';
import {pageState}                              from "../../state"
import CustomTooltip                            from "../../utilities/CustomTooltip";
import DataRowComponent                    from "./DataRowComponent"
import GraphRowComponent                      from "./GraphRowComponent"
import LoadingScreen                            from '../LoadingScreen';

function getColumnNames(isDoubleColumn) {
    const template1 = {style: {textAlign: 'left', backgroundColor: 'gray', width: '100px',}}
    //atentie, initial primele 3 coloane au avut width hardcodat :100,200,150px !
    const template2 = {style: {textAlign: 'center', backgroundColor: 'black', width: '100px',}}
    let result = [];
    ['Sectiune','Indicator','Diviziune'].forEach(colName=>{result.push({...template1,name:colName,colSpan:'1'})});
    ['Jan','Feb','Mar','Apr','May','Jun','Jul','Aug','Sep','Oct','Nov','Dec'].forEach(colName=>{result.push({...template2,name:colName,colSpan:isDoubleColumn?2:1})})
    return result;}

const containsLocalFilterIndicatori = (localFilterIndicatori, row) => localFilterIndicatori.length < 3 ? true : (row.numeSectiune + row.numeIndicator)
    .toLowerCase()
    .includes(localFilterIndicatori.toLowerCase());



function toggableList(list = [], param) {
    return list.indexOf(param) === -1 ? [...list, param] : list.filter((listItem) => listItem !== param);
}

function GeneralBenchTable({isDoubleColumn,pageType}) {

    const [tableData]                           = useAtom(pageState.tableData);
    // const [hasTableUpdated]                     = useAtom(pageState.hasTableUpdated);
    const [showGraph]                           = useAtom(pageState.showGraph);
    const [withNull]                            = useAtom(pageState.withNull);
    const [filterIndicatori]                    = useAtom(pageState.filterIndicatori);

    const [localFilterIndicatori, setLocalFilterIndicatori] = useState('');
    const [openedClasses, setOpenedClasses]     = useState([null]);

    // useEffect(() => {
    //     setTableData(initializeTable(tableData,pageType));
    // }, [hasTableUpdated,pageType]);

    useEffect(() => {
        setLocalFilterIndicatori(filterIndicatori);
    }, [filterIndicatori]);

    console.log('tabelul cu care incep pagina de date :',tableData)
    return (<div style={{minHeight: "200px"}} className='relative'>
        {tableData.length === 0 &&
            <div className="flex justify-center items-center mx-auto absolute top-2/4 right-2/4">
                <LoadingScreen/>
            </div>}
        <table style={{maxHeight: "69vh"}}
               className='text-left block active-scroll border-collapse leading-4 text-sm overflow-scroll'>
            {/* {console.log('opened tabs :',openedClasses)} */}
            <thead style={{zIndex: '1'}} className='sticky top-0'>
            <tr>
                {getColumnNames(isDoubleColumn).map((column, index) => showGraph && column.name === "Diviziune" ? null : <th
                    className='h-12 border border-gray p-1 text-white'
                    style={column.style}
                    key={index}
                    colSpan={column.colSpan}
                >
                    {column.name}
                </th>)}
            </tr>
            </thead>
            <tbody>
            {/*{console.log('cu asta lucram:',tableData)}*/}
            {tableData
                .filter(row => row.afisareStartBench === 1 || containsLocalFilterIndicatori(localFilterIndicatori, row)) // dupa filtrare
                .filter(row => showGraph ? (row.afisareStartBench === 1 || (row.tipGrafic && row.afisareExpandSectiuneBench && openedClasses.indexOf(row.numeSectiune) !== -1)) : 
                row.afisareStartBench === 1  
                || (openedClasses.indexOf(row.numeSectiune) !== -1 && row.afisareExpandSectiuneBench)
                || (openedClasses.indexOf(row.numeSectiune) !== -1 && openedClasses.indexOf(row.numeIndicator) !== -1 && !row.isAllNull) 
                || (openedClasses.indexOf(row.numeSectiune) !== -1 && openedClasses.indexOf(row.numeIndicator) !== -1 && !withNull)
                ).map((row, index) => <tr key={index} style={{height: "48px"}}>
                    <td
                        style={{
                            width: "100px", backgroundColor: row["culoareSectiune"],
                        }}
                        className='p-1 border border-gray pr-8 relative'
                    >
                        {!row.afisareStartBench ? null : (<Fragment>
                            {row["numeSectiune"]}
                            <span
                                id={`sectiune${index}true`}
                                onClick={() => setOpenedClasses(toggableList(openedClasses, row.numeSectiune))}
                                className='material-icons cursor-pointer absolute right-1 top-1/4'
                            >
                          keyboard_arrow_down
                        </span>
                        </Fragment>)}
                    </td>
                    <td
                        style={{width: "200px"}}
                        className='p-1 border border-gray pr-10 relative'
                    >
                        {!row.afisareExpandSectiuneBench ? null : (<Fragment>
                            {row.numeIndicator}
                            {!row.tooltipIndicator ? null : (<div className='absolute right-6 top-1/4'>
                                <CustomTooltip
                                    text={row.tooltipIndicator}
                                    icon={<span
                                        style={{fontSize: "20px"}}
                                        className='material-icons'
                                    >
                                  info
                                </span>}
                                />
                            </div>)}
                            {row["butonDiviziune"] === 1 && !showGraph ? (<span
                                onClick={() => setOpenedClasses(toggableList(openedClasses, row.numeIndicator))}
                                className='material-icons cursor-pointer absolute right-1 top-1/4'
                            >
                            keyboard_arrow_down
                          </span>) : null}
                        </Fragment>)}
                    </td>
                    {showGraph ? (<GraphRowComponent row={row} tableData={tableData} isDoubleColumn={isDoubleColumn}/>) : (
                        <DataRowComponent row={row} isDoubleColumn={isDoubleColumn}/> )}
                </tr>)}
            </tbody>
        </table>
    </div>);
}


export default GeneralBenchTable;
