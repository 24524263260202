import UploadMonthCardComponent from "../components/upload-page-components/UploadMonthCardComponent";
import useAxiosPrivate from "../hooks/useAxiosPrivate";
import { useCallback, useEffect, useState } from "react";

import { userState , state, uploadPageState} from "../state";
import { useAtom } from "jotai";

import ModalComponent from "../components/ModalComponent";

import UploadModal from "../components/upload-page-components/UploadModal";
import ReUploadModal from "../components/upload-page-components/ReUploadModal";
import CnpExceptionModal from "../components/exceptions-components/CnpExceptionModal";
import ExceptionsCard from "../components/upload-page-cards/ExceptionsCard";
import HelpCard from "../components/upload-page-cards/HelpCard";
import { useNavigate } from "react-router-dom";
import homeCard4 from '../images/cards/homeCard4.png';




let modalContent = "";

const currentDate = new Date();
// let subscriptionStartDate = '';


function UploadPage() {
  const [currentCompany] = useAtom(userState.currentCompany)

  let navigate = useNavigate();

  const axiosPrivate = useAxiosPrivate();
  const [companyStartEndDates, setCompanyStartEndDates] = useState([]);
  const [companyRvsUploadDates, setCompanyRvsUploadDates] = useState([]);
  const [companyD112UploadDates, setCompanyD112UploadDates] = useState([]);
  const [companyStatUploadDates, setCompanyStatUploadDates] = useState([]);
  const [companyStatModel, setCompanyStatModel] = useState(false);

  const [uploadingDates, setUploadingDates] = useAtom(uploadPageState.uploadingDates);

  const getCompanyStartEndDates = async () => {
    try {
      const response = await axiosPrivate.get(`/api/get-company-start-end?companyId=${currentCompany.id}`);
      setCompanyStartEndDates(response.data);
    } catch(e) {
      console.log(e);
    }
  }


  const getCompanyRvsUploadDates = async() => {
    try {
      const response = await axiosPrivate.get(`/api/get-company-rvs-uploaded-dates?companyId=${currentCompany.id}`)
      console.log("aici sunt lunile pt care a uploadat deja")
      console.log(response.data)
      let dateObjectsArray = [];
      response.data.forEach(date => dateObjectsArray.push({ perioada: new Date(date.perioada), timeStamp: date.timeStamp, copied: date.prinCopiere }))
      setCompanyRvsUploadDates(dateObjectsArray);
    } catch (e) {
      console.log(e)
    }
  }
  
   const getCompanyD112UploadDates = async() => {
    try {
      const response = await axiosPrivate.get(`/api/get-company-d112-uploaded-dates?companyId=${currentCompany.id}`)
      console.log(response.data)
      let dateObjectsArray = [];
      response.data.forEach(date => dateObjectsArray.push({ perioada: new Date(date.perioada), timeStamp: date.timeStamp }))
      setCompanyD112UploadDates(dateObjectsArray);
    } catch (e) {
      console.log(e)
    }
   }
  
  const getCompanyStatUploadDates = async() => {
    try {
      const response = await axiosPrivate.get(`/api/get-company-stat-uploaded-dates?companyId=${currentCompany.id}`)
      console.log(response.data)
      let dateObjectsArray = [];
      response.data.forEach(date => dateObjectsArray.push({ perioada: new Date(date.perioada), timeStamp: date.timeStamp }))
      setCompanyStatUploadDates(dateObjectsArray);
    } catch (e) {
      console.log(e)
    }
}

  //Array of Dates
  
  const monthDiff = () => {
    let startDate = new Date(companyStartEndDates?.[0]);
    let endDate = new Date(companyStartEndDates?.[1]);
    let months;
    months = (endDate?.getFullYear() - startDate?.getFullYear()) * 12;
    months -= startDate?.getMonth();
    months += endDate?.getMonth();
    return months <= 0 ? 0 : months;
}

  const getContractMonths = () => {
    let startDate = new Date(companyStartEndDates?.[0]);
    const subscribedMonths = monthDiff();
    let months = [];
    for (let i = 0; i < subscribedMonths + 1; i++) {
        months.push(new Date(startDate.getFullYear(), startDate.getMonth() + i, 1))
    }
    return months;
}

  const [modalIsOpen, setIsOpen] = useState(false);
  
  
  const showModalWithContent = (uploadType, dateToSend, fileType) => {
    if (uploadType === "upload") {
      modalContent = <UploadModal setIsOpen={setIsOpen} dateToSend={dateToSend} getCompanyRvsUploadDates={getCompanyRvsUploadDates} getCompanyD112UploadDates={getCompanyD112UploadDates} getCompanyStatUploadDates={getCompanyStatUploadDates} fileType={fileType} />
      setIsOpen(true);
    } else if (uploadType === "reUpload") {
      modalContent = <ReUploadModal setIsOpen={setIsOpen}  dateToSend={dateToSend} getCompanyRvsUploadDates={getCompanyRvsUploadDates} getCompanyD112UploadDates={getCompanyD112UploadDates} getCompanyStatUploadDates={getCompanyStatUploadDates} fileType={fileType} />
      setIsOpen(true);
    }
    
  }
  
  const showExceptionsModal = () => {
    modalContent = <CnpExceptionModal setIsOpen={setIsOpen} />
    setIsOpen(true);
  }
  const isComapnySablonAvailable = async() => {
    const response = await axiosPrivate.get(`/api/company/has-stat-sablon?cif=${currentCompany?.cif}`)
    setCompanyStatModel(response.data);
  }

  useEffect(() => {
    getCompanyStartEndDates();
    getCompanyRvsUploadDates();
    getCompanyD112UploadDates();
    getCompanyStatUploadDates();
    isComapnySablonAvailable();
    // getNext12Monts();
    if (currentCompany.id > 1000000) {
      navigate("/");
    }
    }, [currentCompany])
  
  const contractMonths = getContractMonths();

  return (
    <div>
      <div className='ml-24 mt-24 flex gap-x-8 '>
      <div className="flex flex-col justify-between gap-14">
          {/* TODO sa adaug cartonasul bun  */}
      {/* <div onClick={showExceptionsModal} style={{height: '400px', width: '550px'}} className='pl-8 pr-28 py-8 shadow-lg relative bg-white cursor-pointer'>
        <h3 className='text-primary2 text-xl uppercase'>Exceptii</h3>
        <p className='mt-16 text-sm'>LLLorem, ipsum dolor sit amet consectetur adipisicing elit. Sed doloremque eos laudantium velit ullam, quia optio reiciendis aperiam officiis architecto sequi atque ipsa esse, nesciunt veritatis id quae porro amet suscipit quis placeat iure ea unde. Dignissimos ratione quod praesentium?</p>
        <FontAwesomeIcon
            style={{ color: "#dddfeb" }}
            icon={faUpload}
            className='text-8xl absolute right-6 bottom-6'
          />
      </div> */}
        <ExceptionsCard handleClick={showExceptionsModal} image={homeCard4} />
          <HelpCard />
      </div>
      
      <div style={{maxHeight: '85vh', minWidth: '1150px'}} className='active-scroll flex shadow-lg p-8 pr-12 overflow-y-auto bg-white z-10 justify-center'>
        {/* Left Side  */}
        <div>
          {/* Title  */}
          <div className='flex gap-4 mb-6'>
            <div style={{width: "160px"}} className='bg-primary2 text-white px-4 py-4 text-lg'>
              Luna referinta 
              {/* Luna referinta */}
            </div>
            <div style={{width: "230px"}} className='bg-primary2 text-white px-4 py-4 text-lg'>
                Revisal
                {/* Revisal */}
            </div>
            {/* <div className='bg-primary2 text-white px-4 py-4 w-20 text-lg'>
              Status
            </div> */}
          </div>
          {/* Content  */}
        <div className='border-r-2 pr-8'>
            {contractMonths.map((date, index) => {
              return <UploadMonthCardComponent key={date} companyStatModel={companyStatModel} date={date} companyUploadDates={companyRvsUploadDates} showModalWithContent={showModalWithContent} cardType="rvs" />                
            })}
          </div>
        </div>
          {/* Right Side  */}
          <div className="ml-8">
            <div className="flex gap-4 mb-6">
                <div style={{width: "230px"}} className='bg-primary2 text-white px-4 py-4 text-lg'>
                Stat
              </div>
            </div>
            <div className="border-r-2 pr-8">
              {contractMonths.map((date, index) => {
              return <UploadMonthCardComponent key={date} companyStatModel={companyStatModel} date={date} companyUploadDates={companyStatUploadDates} showModalWithContent={showModalWithContent} cardType="stat" />    
          })}
            </div>
            
          </div>
        <div className='ml-8'>
          {/* Title  */}
          <div className='flex gap-4 mb-6'>
            <div style={{width: "230px"}} className='bg-primary2 text-white px-4 py-4 text-lg'>
              D112
            </div>
            {/* <div className='bg-primary2 text-white px-4 py-4 w-20 text-lg'>
              Status
            </div> */}
          </div>
          {/* Content  */}
            {contractMonths.map((date, index) => {
              return <UploadMonthCardComponent key={date} companyStatModel={companyStatModel} date={date} companyUploadDates={companyD112UploadDates} showModalWithContent={showModalWithContent} cardType="d112" />    
          })}
        </div>
      </div>
      </div>
      <ModalComponent modalIsOpen={modalIsOpen} content={modalContent}  />
    </div>
    
  );
}

// Trimit data in show modal woth content, care il trimite in uploadPage si reuploadPage, apoi in uploadModalButton.
// In stateul global fac un obiect general de state care sa stie despre datile pt care se face upload
// In uploadModalButton, updatez obiecul general de state atunci cand se face upload
// ca sa verific pe uplaodMonthCardComponent, fac un for de fiecare data prin fiecare componenta randata si verific daca data ei coincide cu data flaguita de pe oibectul general
// apoi pe uplaodMonthCardComponent, verific daca e data flaguita in obiecul general, iar daca e flaguita, o pun cu statusul de notYet


export default UploadPage