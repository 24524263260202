import { useEffect } from "react";
import { useAtom } from "jotai";
import { pageState } from "../state";
import GeneralBenchFeatures from "../components/general-bench-components/GeneralBenchFeatures";
import GeneralBenchFetchButton from "../components/general-bench-components/GeneralBenchFetchButton";
import GeneralBenchTable from "../components/general-bench-components/GeneralBenchTable";
import ModalComponent from "../components/ModalComponent";
import { useState } from "react";
import GeneralInfoModal from "../components/general-bench-components/GeneralInfoModal";

function GeneralBenchPage({ isDoubleColumn, pageType }) {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [, setWithNull] = useAtom(pageState.withNull);
  const [, setSelectedAggregationGroup] = useAtom(
    pageState.selectedAggregationGroup
  );
  const [, setSelectedAggregationLevelOption] = useAtom(
    pageState.selectedAggregationLevelOption
  );
  const [, setCorNameOptions] = useAtom(pageState.corNameOptions);
  const [, setSelectedCorNameOption] = useAtom(pageState.selectedCorNameOption);
  const [, setSelectedCorLevelOption] = useAtom(
    pageState.selectedCorLevelOption
  );
  const [, setFilterIndicatori] = useAtom(pageState.filterIndicatori);
  const [, setSelectedYearOption] = useAtom(pageState.selectedYearOption);
  const [, setSelectedStandardOption] = useAtom(
    pageState.selectedStandardOption
  );
  const [, setTableData] = useAtom(pageState.tableData);

  useEffect(() => {
    console.log("incepe efectul de la benchpage - component mount");
    return () => {
      console.log("incepe efectul de la benchpage - component UNMOUNT");
      setWithNull(true);
      setSelectedAggregationGroup({ label: "1 | Toata firma", value: "1" });
      setSelectedAggregationLevelOption("0");
      setCorNameOptions([{ label: "0 | Toata firma", value: "0" }]);
      setSelectedCorNameOption({ label: "0 | Toata firma", value: "0" });
      setSelectedCorLevelOption("0");
      setSelectedStandardOption({ label: "COR", value: "1" });
      setSelectedYearOption(new Date().getFullYear().toString());
      setTableData([]);
      setFilterIndicatori("");
    };
  }, [pageType]);

  const showInfoModal = () => {
    setModalIsOpen(true);
  };

  return (
    <div style={{ maxWidth: "1700px" }} className='flex mx-28 mt-24'>
      <div style={{ zIndex: "3" }} className=' bg-white w-full p-4'>
        <div className='flex justify-between items-center'>
          <div className='flex gap-4 cursor-pointer' onClick={showInfoModal}>
            <h1 className='text-2xl font-bold text-primary2'>
              {pageType === "kpiPage"
                ? "INDICATORII TAI"
                : pageType === "benchPage"
                ? "INDICATORI BENCHMARKING"
                : "INDICATORI BENCHMARKING EXTINSI"}
            </h1>{" "}
            {/* <CustomTooltip
              text='Info'
              icon={
                <span style={{ fontSize: "40px" }} className='material-icons'>
                  info
                </span>
              }
            /> */}
            <span style={{ fontSize: "40px" }} className='material-icons'>
              info
            </span>
          </div>
          <GeneralBenchFeatures
            pageType={pageType}
            isDoubleColumn={isDoubleColumn}
          />
        </div>
        <div className='flex justify-between items-center'>
          <GeneralBenchFetchButton
            pageType={pageType}
            isDoubleColumn={isDoubleColumn}
          />
        </div>
        <div>
          <GeneralBenchTable
            pageType={pageType}
            isDoubleColumn={isDoubleColumn}
          />
        </div>
      </div>
      <ModalComponent
        modalIsOpen={modalIsOpen}
        content={<GeneralInfoModal setIsOpen={setModalIsOpen} pageType={pageType} />}
      />
    </div>
  );
}

export default GeneralBenchPage;
