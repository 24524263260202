import React from "react";
import HelpCard from "../components/upload-page-cards/HelpCard";
import YourSubscriptionsCard from "../components/home-page-cards/YourSubscriptionsCard";
import homeCard2 from "../images/cards/homeCard2.png";
import SubscriptionSelectedPage from "../components/subscriptions-page-components/SubscriptionSelectedPage";
import { useAtom } from "jotai";
import { subscriptionState } from "../state";
import { useEffect } from "react";


// const selectedSubscription = atom("Abonamet promotional 1 an + 1 an gratuit");
// const selectedCompaniesNumber = atom(1);
// const employeesNumber = atom(1000);
// const addOn = atom(false);
// const listPrice = atom(0);
// const discountName = atom("Discount promo");
// const discount = atom(0);
// const finalPrice = atom(0);
// const selectedPage = atom(1);
// const addedCompanies = atom([]);
// const mainCompany = atom("");

function SubscriptionsPage() {

  const [, setSelectedPage] = useAtom(subscriptionState.selectedPage);
  const [, setSelectedSubscription] = useAtom(subscriptionState.selectedSubscription);
  const [, setSelectedCompaniesNumber] = useAtom(subscriptionState.selectedCompaniesNumber);
  const [, setEmployeesNumber] = useAtom(subscriptionState.employeesNumber);
  const [, setAddon] = useAtom(subscriptionState.addOn);
  const [, setVipAddon] = useAtom(subscriptionState.vipAddon);
  const [, setListPrice] = useAtom(subscriptionState.listPrice);
  const [, setDiscountName] = useAtom(subscriptionState.discountName);
  const [, setFinalPrice] = useAtom(subscriptionState.finalPrice);
  const [, setAddedCompanies] = useAtom(subscriptionState.addedCompanies);
  const [, setMainCompany] = useAtom(subscriptionState.mainCompany);
  const [, setDiscount] = useAtom(subscriptionState.discount);


  useEffect(() => {
    return () => {
      setSelectedPage(1);
      setSelectedSubscription("Abonamet promotional 1 an + 1 an gratuit")
      setSelectedCompaniesNumber(1);
      setEmployeesNumber(1000);
      setAddon(false);
      setVipAddon(false);
      setListPrice(0);
      setDiscountName("")
      setFinalPrice(0);
      setAddedCompanies([]);
      setMainCompany("");
      setDiscount(0);
    }  
  }, [])

  return (
    <div style={{ maxWidth: "1700px" }} className='flex mx-24 mt-24'>
      <div style={{ zIndex: "3" }} className='w-full flex gap-8'>
        <div className='flex flex-col justify-between gap-14'>
          <YourSubscriptionsCard image={homeCard2} />
          <HelpCard />
        </div>
        <SubscriptionSelectedPage />
      </div>
    </div>
  );
}

export default SubscriptionsPage;
