import React, { Fragment } from 'react';
import CustomTooltip from '../../utilities/CustomTooltip';
import {formatDataCells} from '../../utilities/TableUtilities';

const months = [
	'jan',
	'feb',
	'mar',
	'apr',
	'may',
	'jun',
	'jul',
	'aug',
	'sep',
	'oct',
	'nov',
	'dec',
];

function DataRowComponent({row,isDoubleColumn}) {
	return (
		<Fragment>
			<td
				style={{ width: '150px' }}
				className="p-1 border border-gray relative"
			>
				{row['afisajDiviziune']}
				{row.afisajDiviziune && row.tooltipDiviziune && (
					<div className="absolute right-6 top-1/4">
						<CustomTooltip
							text={row.tooltipDiviziune}
							icon={
								<span style={{ fontSize: '20px' }} className="material-icons">
									info
								</span>
							}
						/>
					</div>
				)}
			</td>
			{months.map((month, index) => (
				<Fragment key={index}>
					<td
					 style={row[month]==row["maxValue"]&&row["maxValue"]!==0?{backgroundColor:'#c6cbec'}:null}
						className="p-1 border border-gray px-2 relative text-center ">
						{formatDataCells(row[month], row.tipValoare, row.nrZecimale)}
					</td>
					{isDoubleColumn?<>
					<td
						style={{ backgroundColor: '#d9d9d9' }}
						className="p-1 border border-gray px-2 relative text-center"
					>
						{formatDataCells(
							row[`bench_${index + 1}`],
							row.tipValoare,
							row.nrZecimale
						)}
					</td>
					</>:<></>}
				</Fragment>
			))}
		</Fragment>
	);
}

export default DataRowComponent;
