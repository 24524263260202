import { useAtom } from "jotai";
import { subscriptionState } from "../../../state";

function ShowMainCompany() {
  const [mainCompany] = useAtom(subscriptionState.mainCompany);

  return (
    <div className='text-xl flex-1'>
      <h2 className='text-primary2 text-2xl font-bold'>
        Adauga companie
      </h2>
      <div className='flex items-center gap-24 mt-4 justify-between'>
        <p className='font-bold ml-2'>CIF</p>
        <p className='border py-1 px-2 border-gray bg-lightGray w-64'>
          {mainCompany.cif}
        </p>
      </div>
      <div className='flex flex-col gap-2 mt-4'>
        <p className='font-bold ml-2'>Denumire Firma</p>
        <p className='border py-1 px-2 border-gray bg-lightGray'>
          {mainCompany.companyName}
        </p>
      </div>
        <div className='flex flex-col gap-2 mt-4'>
          <p className='font-bold ml-2'>Reg Comert</p>
          <p className='border py-1 px-2 border-gray bg-lightGray '>
            {mainCompany.regComert}
          </p>
        </div>
        {/* <div className='flex flex-col gap-2'>
          <p className='font-bold ml-2'>Siruta</p>
          <p className='border py-1 px-2 border-gray bg-lightGray w-48'>
            {mainCompany.siruta}
          </p>
        </div> */}
      <div className='flex flex-col gap-2 mt-4'>
        <p className='font-bold ml-2'>Adresa</p>
        <p className='border py-1 px-2 border-gray bg-lightGray'>
          {mainCompany.address}
        </p>
      </div>
      {/* <div className='flex justify-between gap-2 mt-4'>
        <div className='flex flex-col gap-2'>
          <p className='font-bold ml-2'>Nr mediu salariati</p>
          <p className='border py-1 px-2 border-gray bg-lightGray'>
            {mainCompany.employees}
          </p>
        </div>
        <div className='flex flex-col gap-2'>
          <p className='font-bold ml-2'>Cod CAEN</p>
          <p className='border py-1 px-2 border-gray bg-lightGray w-48'>
            {mainCompany.caen}
          </p>
        </div>
      </div> */}
    </div>
  );
}

export default ShowMainCompany;
