import { pageState, userState } from "../../state";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import { useAtom } from "jotai";
import { saveAsXlsxFile } from "../../utilities/FileHandler";

// de ce nu preiei bench type trimis prin props?
function GeneralBenchFeatures({ isDoubleColumn }) {
  const axiosPrivate = useAxiosPrivate();

  const [company] = useAtom(userState.currentCompany);
  const [user] = useAtom(userState.user);

  const [withNull, setWithNull] = useAtom(pageState.withNull);
  const [showGraph, setShowGraph] = useAtom(pageState.showGraph);
  const [selectedYearOption] = useAtom(pageState.selectedYearOption);
  const [selectedCorLevelOption] = useAtom(pageState.selectedCorLevelOption);
  const [selectedCorNameOption] = useAtom(pageState.selectedCorNameOption);
  const [corNameOptions] = useAtom(pageState.corNameOptions);
  const [selectedAggregationLevelOption] = useAtom(
    pageState.selectedAggregationLevelOption
  );
  const [selectedAggregationGroup] = useAtom(
    pageState.selectedAggregationGroup
  );
  const [selectedStandardOption] = useAtom(pageState.selectedStandardOption);

  const toggleNulls = () => {
    setWithNull((prevValue) => !prevValue);
  };

    const exportTable = async () => {
        // console.log('asa era inainte !',`/api/generate_xlsx_kpi?cui=${company.cif}&year=${selectedYearOption}&corLevel=${selectedCorLevelOption}&cor=${selectedCorNameOption.value ? selectedCorNameOption.value : corNameOptions[0].value}&companyId=${company.id}&standard=${selectedStandardOption.value}`
        // )
        const url = isDoubleColumn?
                    `/api/generate_xlsx_bench?cui=${company.cif}&year=${selectedYearOption}&corLevel=${selectedCorLevelOption}&cor=${selectedCorNameOption.value? selectedCorNameOption.value:corNameOptions[0].value}&companyId=${company.id}&standard=${selectedStandardOption.value}&aggregationLevel=${selectedAggregationLevelOption}&aggregationGroup=${selectedAggregationGroup.value}&userId=${user.id}`
            :
                    `/api/generate_xlsx_kpi?cui=${company.cif}&year=${selectedYearOption}&corLevel=${selectedCorLevelOption}&cor=${selectedCorNameOption.value?selectedCorNameOption.value:corNameOptions[0].value}&companyId=${company.id}&standard=${selectedStandardOption.value}`;
                        // &standard=${selectedStandardOption.value}`;//era inainte ! !
        console.log('aici incearca :',url)
        try {
            const response = await axiosPrivate.get(url);  //TODO: @Robert pls review !!
            saveAsXlsxFile(response.data, "TactiQ.xlsx");
        } catch (e) {
            console.log(e)
        }
    };


  return (
    <div className='flex gap-8 mr-12'>
      <div
        style={{
          cursor: showGraph && "not-allowed",
          color: showGraph && "gray",
        }}
        className='flex items-center gap-4 cursor-pointer'
        onClick={() => !showGraph && toggleNulls()}
      >
        <span
          style={{
            fontSize: "64px",
            color: withNull ? (showGraph ? "gray" : "red") : "green",
          }}
          className='material-icons '
        >
          {withNull ? "toggle_off" : "toggle_on"}
        </span>
        <span className='w-20'>Vezi toate diviziunile</span>
      </div>
      <div
        className='flex items-center gap-4 cursor-pointer'
        onClick={() => {
          setWithNull(true);
          setShowGraph((prevValue) => !prevValue);
        }}
      >
        <span style={{ fontSize: "50px" }} className='material-icons'>
          analytics
        </span>
        <span className='w-14 font-bold'>
          {showGraph ? "Vezi Indicatori" : "Vezi Grafice"}
        </span>
      </div>
      <div
        onClick={exportTable}
        className='flex items-center gap-4 cursor-pointer'
      >
        <span style={{ fontSize: "50px" }} className='material-icons'>
          file_download
        </span>
        <span className='w-14 font-bold'>Exporta Rezultate</span>
      </div>
    </div>
  );
}

export default GeneralBenchFeatures;
