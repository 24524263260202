import { useAtom } from "jotai";
import { subscriptionState } from "../../../state";

function SelectedSubscriptionInfo() {

    const [selectedSubscription] = useAtom(subscriptionState.selectedSubscription);
    const [selectedCompaniesNumber] = useAtom(subscriptionState.selectedCompaniesNumber);
    const [finalPrice] = useAtom(subscriptionState.finalPrice)

  return (
    <div className='flex gap-6 text-xl font-bold'>
          <div>
              <p className="ml-2">Abonament Selectat</p>
              <p className="bg-lightGray p-2 pr-12 border text-gray">{selectedSubscription}</p>
          </div>
          <div>
              <p className="ml-2">Nr companii</p>
              <p className="bg-lightGray p-2 pr-12 border text-gray">{selectedCompaniesNumber}</p>
          </div>
          <div>
              <p className="ml-2">Pret</p>
              <p className="bg-lightGray p-2 pr-12 border text-gray">{finalPrice} EUR + TVA</p>
          </div>
    </div>
  )
}

export default SelectedSubscriptionInfo