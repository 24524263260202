const tip_valoare_options = {1:"decimal",2:"percent",3:"decimal",4:"decimal"}

export function formatDataCells(value,tip_valoare,nr_zecimale){
    if (!value) return null
    return new Intl.NumberFormat("default", {
            style: tip_valoare_options[tip_valoare],
            minimumFractionDigits: nr_zecimale,
            maximumFractionDigits: nr_zecimale,
        }).format(tip_valoare===2?(value/100):(value));
}
// export default formatDataCells;

export const initializeTable = (tableContent, pageType) => {
  let monthsRecorded = new Set()
  const months=['jan',"feb","mar","apr","may",'jun',"jul","aug","sep","oct","nov","dec","bench_1","bench_2","bench_3","bench_2","bench_4","bench_5","bench_6","bench_7","bench_8","bench_9","bench_10","bench_11","bench_12"]
    const result = tableContent.map((object) => {
      //initialize
      var response = {...object,
        show: object['afisareStart'] === 1,
        sectiuneOpen: false,
        indicatorOpen: false,
        tipValoare: object.numeIndicator.indexOf("%")===-1?object.tipValoare:2,
      }
     
      //format
      let minValue=99999999
      let maxValue=0
      let isAllNull = true
      // const lastMonthPos=months.indexOf(lastMonth)
      months.forEach((month,index) => {
        if (!(object[month]===null || object[month]===undefined)) 
        {  
          monthsRecorded.add(month)
          isAllNull = false
          if (month.indexOf("bench")===-1){ //ignore secondary column in min max values calculation
            if (object[month]!=null) minValue = Math.min(parseFloat(object[month]),minValue)
            maxValue = Math.max(parseFloat(object[month]),maxValue)
          }
          } 
        }
        )
      //set final values
      response = {...response,
        isAllNull:isAllNull,
        minValue:minValue,
        maxValue:maxValue,
      }
   
      //merge data for kpiPage
      return pageType==='kpiPage'?
          {...response,
            numeGrupDiviziuneAfis: object['numeIndicator'],
            idGrupDiviziuneAfis: object['idDiviziune'],
            afisareStartBench: object['afisareStart'],
            afisareExpandSectiuneBench: object['afisareExpandSectiune'],
            afisareExpandDiviziune1Bench: object['afisareExpandDiviziune1'],
            afisareExpandDiviziune2Bench: object['afisareExpandDiviziune2'],
          }
          :response
    })

    result.sort((a,b)=> {
      if(a.idSectiune>b.idSectiune) return 1
      if(a.idSectiune<b.idSectiune) return -1
      if(a.idIndicator>b.idIndicator) return 1
      if(a.idIndicator<b.idIndicator) return -1
      if(a.idDiviziune>b.idDiviziune) return 1
      if(a.idDiviziune<b.idDiviziune) return -1
      return 0
      })

      //map with 0 kpis from monthswith records that do not have values
      return result.map((object) => {return {...object,
        jan:monthsRecorded.has("jan")&object.jan===null?"0":object.jan,
        feb:monthsRecorded.has("feb")&object.feb===null?"0":object.feb,
        mar:monthsRecorded.has("mar")&object.mar===null?"0":object.mar,
        apr:monthsRecorded.has("apr")&object.apr===null?"0":object.apr,
        may:monthsRecorded.has("may")&object.may===null?"0":object.may,
        jun:monthsRecorded.has("jun")&object.jun===null?"0":object.jun,
        jul:monthsRecorded.has("jul")&object.jul===null?"0":object.jul,
        aug:monthsRecorded.has("aug")&object.aug===null?"0":object.aug,
        sep:monthsRecorded.has("sep")&object.sep===null?"0":object.sep,
        oct:monthsRecorded.has("oct")&object.oct===null?"0":object.oct,
        nov:monthsRecorded.has("nov")&object.nov===null?"0":object.nov,
        dec:monthsRecorded.has("dec")&object.dec===null?"0":object.dec,
  
      }})    

      // return result;
  };
