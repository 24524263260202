import { ticketsState } from "../../../state";
import { useAtom } from "jotai";
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";

function TicketsList({resetState}) {
  const [companyTickets, setCompanyTickets] = useAtom(
    ticketsState.companyTickets
  );
  const [, setIsTicketClicked] = useAtom(ticketsState.isTicketClicked);
  const [, setCurrentTicketData] = useAtom(ticketsState.currentTicketData);
  const [, setCurrentTicketConversation] = useAtom(ticketsState.currentTicketConversation);
  const axiosPrivate = useAxiosPrivate();

  const handleTicketClick = async (e) => {
    const ticketId = e.target.id;
    console.log(e.target.id)
    setIsTicketClicked(true);
    try {
      const ticketConversation = await axiosPrivate.get(`/ticket-conversation?ticketId=${ticketId}`);
      console.log(ticketConversation.data);
      // set ticket headers/ data 
      setCurrentTicketData(getTicketById(ticketId));
      setCurrentTicketConversation(ticketConversation.data);
    } catch (e) {
      console.log(e)
    }
  }

    const getTicketById = (ticketId) => {
      return companyTickets.find((ticket) => ticket.idTichet == ticketId);
    }

    const tempTickets = [
      {
        idTichet: 13,
        subiect: "SubiectTest",
        dataDeschidere: "2022-10-28 12:46:04.932131",
        dataUltimuluiUpdate: "1.2.2022",
        utimulReply: "Robert",
        status: "deschis",
        categorie: "O categorie"
      },
      
    ];

    return (
      <div style={{ maxHeight: '300px' }} className='overflow-auto'>
        <div className='flex gap-4 text-lightGray text-sm'>
          <div className='bg-primary2 p-2 w-16'>id</div>
          <div className='bg-primary2 p-2 w-60'>Subiect</div>
          <div className='bg-primary2 p-2 w-48'>Data deschidere</div>
          <div className='bg-primary2 p-2 w-48'>Data ultimului update</div>
          <div className='bg-primary2 p-2 w-28'>Ultimul reply</div>
          <div className='bg-primary2 p-2 w-48'>Status</div>
        </div>
        {/* for each ticket i receive from fetch */}
        {companyTickets.map((ticket) => {
          return (
            <div key={ticket.idTichet} className='flex gap-4 text-sm mt-4' >
              <div className='p-2 w-16 overflow-auto border-2'>{ticket.idTichet}</div>
              <div className='p-2 w-60 overflow-auto border-2'>{ticket.subiect}</div>
              <div className='p-2 w-48 overflow-auto border-2'>{new Date(ticket.dataDeschidere).toLocaleDateString()}</div>
              <div className='p-2 w-48 overflow-auto border-2'>{new Date(ticket.lastUpdate).toLocaleString()}</div>
              <div className='p-2 w-28 overflow-auto border-2'>{ticket.ultimulReply}</div>
              <div className="w-48 flex gap-2">
                <div className='p-2 w-36 overflow-auto border-2'>{ticket.status}</div>
                <span style={{ fontSize: "42px" }} className="material-icons cursor-pointer" onClick={handleTicketClick} id={ticket.idTichet}>youtube_searched_for</span>
              </div>
              
            </div>
          );
        })}
      </div>
    );
  }


export default TicketsList;
