import HomeCard from "../components/home-page-cards/HomeCard"
import YourSubscriptionsCard from "../components/home-page-cards/YourSubscriptionsCard"
import ExceptionsCard from "../components/upload-page-cards/ExceptionsCard"
import ImageCard from "../components/home-page-cards/ImageCard"

import homeCard1 from '../images/cards/homeCard1.png'
import homeCard2 from '../images/cards/homeCard2.png'
import homeCard3 from '../images/cards/homeCard3.png'
import homeCard4 from '../images/cards/homeCard4.png'
import homeCard5 from '../images/cards/homeCard5.png'
import homeCard6 from '../images/cards/homeCard6.png'
import { useAtom } from "jotai"
import { userState } from "../state"


function HomePage2() {

  const [currentCompany] = useAtom(userState.currentCompany);

    return (
      <div>
          <div className='grid 2xl:grid-cols-3 lg:grid-cols-3 md:grid-cols-1 grid-rows-2 gap-y-8 gap-x-8 mx-28 mt-24'>
            <ExceptionsCard image={homeCard1} isOnHomePage={true} />
            <YourSubscriptionsCard image={homeCard2} />
            <ImageCard image={homeCard3} />
            <HomeCard image={homeCard4} path='/upload' title="1. INCARCA DATE" icon='file_upload' text='Am redus si simplificat la maxim efortul tau. Tot ce este nevoie sa faci este sa uploadezi lunar fisiere pe care deja le ai: revisal, D112. Toate datele incarcate de tine sunt anonimizate automat, conform GDPR. In situatia in care doresti sa excluzi complet anumite seturi de date configureaza exceptiile in pagina de Setare Exceptii' />
            <HomeCard image={homeCard5} path='/kpi' title="2. Vezi indicatorii tai" icon='stacked_bar_chart' text='Calculam automat peste 100 de indicatori lunari cheie pentru compania ta iar lista este in continua dezvoltare. Afisam rezultatele structurat si facilitam analiza evolutiei lunare a acestora si identificarea momentelor de schimbare. Pentru a face experienta completa am prevazut optiunea de a vizualiza datele in forma grafica' />
            <HomeCard image={homeCard6} path='/bench' title='3. Benchmarking' icon='switch_access_shortcut_add' text='Obtine raspunsuri valide la intrebari care te preocupa constant (costuri, evolutie salarii, etc). Analizeaza oricand rezultatele tale raportat la industria si/ sau locatia in care iti desfasori activitatea (nu doar 1 pe an cum sunt realizate cele mai multe studii). Identifica momentele de schimbare, analizeaza datele evolutiv si fundamenteaza-ti, valideaza-ti sau ajusteaza-ti strategia si/ sau politicile HR' />
          </div>
    </div>
  )
}

export default HomePage2