import React from "react";
import kpiImage from "../../images/helpImages/Modal_indicatoriiTai.png";
import benchImage from "../../images/helpImages/Modal_bench.png";
import extendedBenchImage from "../../images/helpImages/Modal_indicatori_extinsi.png";

let title = "";
let image = "";

function GeneralInfoModal({ setIsOpen, pageType }) {
  if (pageType === "kpiPage") {
    title = "PAGINA INDICATORII TAI";
    image = kpiImage;
  } else if (pageType === "benchPage") {
    title = "PAGINA INDICATORI BENCHMARKING";
    image = benchImage;
  } else if (pageType === "exbenchPage") {
    title = "PAGINA INDICATORI BENCHMARKING EXTINSI";
    image = extendedBenchImage;
  }
    return (
      <div>
        <div className='bg-primary2 text-white w-full h-28 text-center flex items-center px-14'>
          {/* Header Part  */}
          <div className='flex justify-between w-full text-3xl'>
            <p className='font-black'>{title}</p>
            <span
              onClick={() => setIsOpen(false)}
              className='material-icons cursor-pointer md-48'
            >
              cancel
            </span>
          </div>
        </div>
        <div className='mt-24'>
          <img src={image} alt='' />
        </div>
      </div>
    );
}

export default GeneralInfoModal;
