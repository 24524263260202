import React from "react";
import Dropdown from "react-dropdown";
import { useState } from "react";
import { useAtom } from "jotai";
import { pageState, userState, yourFunctionsState } from "../../state";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import { useEffect } from "react";
import { date } from "yup";

const formatYourFunctionsData = (data) => {



  if (!data) return null;
  // console.log(data);
  const response = data?.map((row) => {
    // console.log(row);
    return {
      ...row,
      isAllNull: !(
        row.indicator1 &&
        row.indicator2 &&
        row.indicator3 &&
        row.indicator4 &&
        row.indicator5
      ),
      isExpanded: row.nivel === 1,
      parent: row.nivel<5?row.cor.slice(0, -1):row.cor.slice(0, -2),
    };
  });

  response.sort((a, b) => {
    if (a.nivel1 > b.nivel1) return 1;
    if (a.nivel1 < b.nivel1) return -1;
    if (a.nivel2 > b.nivel2) return 1;
    if (a.nivel2 < b.nivel2) return -1;
    if (a.nivel3 > b.nivel3) return 1;
    if (a.nivel3 < b.nivel3) return -1;
    if (a.nivel4 > b.nivel4) return 1;
    if (a.nivel4 < b.nivel4) return -1;
    return 0;
  });
  // console.log(response);
  return response;
};

function YourFunctionsFetch() {
  const [standardOptions] = useState([
    { label: "COR", value: "1" },
    { label: "ISCO", value: "2" },
  ]);

  const axiosPrivate = useAxiosPrivate();

  const [, setOptions] = useAtom(yourFunctionsState.columnNames);
  const [, setYourFunctions] = useAtom(yourFunctionsState.yourFunctions);
  const [yourFunctionsWithNull, setYourFunctionsWithNull] = useAtom(
    yourFunctionsState.yourFunctionsWithNull
  );

  const [company] = useAtom(userState.currentCompany);

  const [selectedStandardOption, setSelectedStandardOption] = useAtom(
    pageState.selectedStandardOption
  );
  const [selectedYearOption, setSelectedYearOption] = useAtom(
    pageState.selectedYearOption
  );

  const [yearOptions, setYearOptions] = useState([]);
  const [periodYear, setPeriodYear] = useState("");
  const [periodMonth, setPeriodMonth] = useState("");

  const handleStandardChange = (e) => {
    setSelectedStandardOption(e);
    //romana
    console.log(e.value)
    if (e.value === '1') {
      setOptions([
        { label: "Numar Angajati EOM", value: "1" },
        { label: "Angajari YTD", value: "2" },
        { label: "Incetari YTD", value: "3" },
        { label: "Fond salarii Brute YTD", value: "4" },
        { label: "Total Venituri Brute YTD", value: "5" },
      ]);
      //engleza
    } else if (e.value === '2') {
      setOptions([
        { label: "Headcount EOM", value: "1" },
        { label: "Hires YTD", value: "2" },
        { label: "Terminations YTD", value: "3" },
        { label: "Total Gross Salaries YTD", value: "4" },
        { label: "Total Gross Income YTD", value: "5" },
      ]);
    
    }
  };

  const getYearsOptions = async () => {
    try {
      const response = await axiosPrivate.get(
        `/api/get-company-years?cui=${company.cif}`
      );
      console.log(response.data.map((obj) => obj.year));
      setYearOptions(response.data.map((obj) => obj.year));
    } catch (e) {
      console.log(e);
    }
  };

  const getYourFunctions = async () => {
    const response = await axiosPrivate.get(`/your-functions`, {
      params: {
        cui: company.cif,
        year: selectedYearOption,
        standard: selectedStandardOption.value,
      },
    });
    console.log("--------+--------");
    console.log(response.data);
    setYourFunctions(
      formatYourFunctionsData(response.data, yourFunctionsWithNull)
    );
  };

  const getLastUploadDate = async () => {
    try {
      const response = await axiosPrivate.get(`/your-functions/last-date`, {
        params: {
          cui: company.cif
        }
      })
      console.log(response.data);
      setPeriodYear(response.data.perioada.substring(0, 4))
      const date = new Date();
      date.setMonth(response.data.perioada.substring(4) - 1)
      setPeriodMonth(date.toLocaleString('ro-RO', { month: 'long' }).toUpperCase());
    } catch (e) {
      console.log(e)
    }
  }

  useEffect(() => {
    getYearsOptions();
    getYourFunctions();
    getLastUploadDate();
  }, [company]);

  return (
    <div>
      <div className='flex items-center gap-8 text-sm'>
        <div>
          <p className='ml-2'>Standard</p>
          <Dropdown
            menuClassName='active-scroll'
            className='w-24'
            controlClassName='cursor-pointer'
            options={standardOptions}
            value={selectedStandardOption}
            onChange={handleStandardChange}
          />
        </div>
        <div>
          <p className='ml-2'>An</p>
          <Dropdown
            menuClassName='active-scroll'
            className='w-24'
            controlClassName='cursor-pointer'
            options={yearOptions}
            value={selectedYearOption}
            onChange={(e) => setSelectedYearOption(e.value)}
          />
        </div>
        <span
          style={{ fontSize: "58px" }}
          onClick={getYourFunctions}
          className='material-icons mt-6 cursor-pointer text-green'
        >
          play_circle
        </span>
        <span
          className='material-icons cursor-pointer self-end'
          style={{fontSize: "60px", color: yourFunctionsWithNull ? "green" : "red"}}
          onClick={() => setYourFunctionsWithNull((prevValue) => !prevValue)}
        >
          {yourFunctionsWithNull ? "toggle_on" : "toggle_off"}
        </span>
        <div className="flex flex-col">
          <span className="ml-3">Ultima Luna</span>
          <div className="bg-lightGray p-3 w-48"><span>{periodMonth} {periodYear}</span></div>
        </div>
        
      </div>
    </div>
  );
}

export default YourFunctionsFetch;
