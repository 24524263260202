import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useAtom } from "jotai";
import { state, userState } from "../state";

function NavigationDropdown() {

  const navigate = useNavigate();

  const [token, setToken] = useAtom(state.token);
  
  const [currentCompany, setCurrentCompany] = useAtom(userState.currentCompany);

  const logout = () => {
    setToken("");
    setCurrentCompany("");
    localStorage.clear();
    navigate("/login");
  }

  return (
    <div style={{zIndex: 999}} className='absolute -left-24 top-14 py-2 shadow bg-white rounded-md nav-dropdown'>
      <ul className=' w-36'>
        <li className='px-4 hover:bg-secondary cursor-not-allowed'>
          <span className='material-icons'>person</span>
        </li>
        <li>
          <div
            className='my-4'
            style={{ borderTop: "1px solid #e3e6f0" }}
          ></div>
        </li>
        <li className='px-4 hover:bg-secondary cursor-pointer' onClick={logout}>
          <span className='material-icons'>logout</span>
        </li>
      </ul>
    </div>
  );
}

export default NavigationDropdown;
