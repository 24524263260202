import { useAtom } from "jotai"
import { useEffect } from "react";
import { useState } from "react";
import { toast } from "react-toastify";
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
import { subscriptionState } from "../../../state"
import { JUDETE } from "../judete";
import InputWarning from "../common/InputWarning";


const defaultValues = {
    cif: "",
    companyName: "",
    regComert: "",
    address: "",
    employees: "",
    caen: "",
    usedApplication: 0,
    judet: 0,
}

function AddCompany() {

    const axiosPrivate = useAxiosPrivate();

    const [pressedAddCompany, setPressedAddCompany] = useState(false);

    const [usedApplicationOptions, setUsedApplicationOptions] = useState([]);

    const [addedCompanies, setAddedCompanies] = useAtom(subscriptionState.addedCompanies);
    const [, setMainCompany] = useAtom(subscriptionState.mainCompany);
    const [selectedCompaniesNumber] = useAtom(subscriptionState.selectedCompaniesNumber);

    const [formFields, setFormFields] = useState(defaultValues);

    const { cif, companyName, regComert, address, employees, caen, usedApplication, judet } = formFields;

    const handleChange = (e) => {
        const { name, value } = e.target;

        setFormFields({ ...formFields, [name]: value });

    }

    // const handleJudetChange = (e) => {
    //     console.log(e.target.value)
    //     setFormFields({ ...formFields, "judet": e.target.value });
    // }

    const getUsedApplications = async () => {
        const response = await axiosPrivate.get("/api/used-applications");
        console.log(response);
        setUsedApplicationOptions(response.data);
    }

    useEffect(() => {
        // setUsedApplicationOptions
        getUsedApplications();
    }, [])


    const getCompanyDetails = async () => {
        try {
            const response = await axiosPrivate.get(`/api/company-details?cif=${cif}`);


            const details = response.data.found[0];
            console.log(response.data.found[0]);
            if (!details.denumire) {
                toast.error("CIF-ul nu exista, te rugam sa incerci din nou", { position: "top-center" })
                return;
            }
            
            setFormFields(defaultValues);
            setFormFields({ ...formFields, 'companyName': details.denumire, "address": details.adresa, "regComert": details.nrRegCom, caen: "", "employees": "" });
            console.log(addedCompanies);
        } catch (e) {
            console.log(e);
        }
    }

    const addCompany = (e) => {
        e.preventDefault();
        setPressedAddCompany(true);
        for (const [key, value] of Object.entries(formFields)) {
            console.log(`${key}: ${value}`);
            if (!value) {
                toast.warn("Va rugam sa completati toate campurile", { position: "top-center" });
                return;
            }
        }
        
        
        if (judet === 0) {
            toast.warn("Va rugam sa selectati un judet", { position: "top-center" });
            return;
        }
        setAddedCompanies(prevValue => [...prevValue, formFields]);
        if (!addedCompanies.length) {
            setMainCompany(formFields);
        }
        setFormFields(defaultValues);
        setPressedAddCompany(false);
    }
    

  return (
    <div className="">
          <h2 className="text-primary2 text-2xl font-bold flex-1">Adauga companie</h2>
          <form className="text-xl" onSubmit={addCompany}>
              <div className="flex items-center gap-8 mt-4 justify-left relative">
                  <label className="font-bold ml-2" htmlFor="cif">CIF (fara RO) *</label>
                  <input disabled={addedCompanies.length >= parseInt(selectedCompaniesNumber)} onChange={handleChange} className={pressedAddCompany && !cif ? "border py-1 px-2 border-accent" : "border py-1 px-2 border-gray" } type="text" name="cif" id='cif' value={cif} />
                  <span style={{fontSize: "45px"}} onClick={getCompanyDetails} className="block material-icons text-green cursor-pointer">published_with_changes</span>    
                  {pressedAddCompany && !cif  && <InputWarning text='CIF-ul este necesar'/>}
              </div>
              <div className="flex flex-col gap-2 mt-4 relative">
                  <label className="font-bold ml-2" htmlFor="companyName">Denumire Firma *</label>
                  <input disabled={addedCompanies.length >= parseInt(selectedCompaniesNumber)} onChange={handleChange} className={pressedAddCompany && !companyName  ? "border border-accent py-1 px-2" : "border border-gray py-1 px-2"} type="text" name="companyName" id="companyName" value={companyName} />
                  {pressedAddCompany && !companyName  && <InputWarning text='Denumirea firmei este necesara'/>}
              </div>
              <div className="flex justify-between gap-2 mt-4 relative">
                  <div className="flex flex-col gap-2">
                      <label className="font-bold ml-2" htmlFor="regComert">Reg Comert *</label>
                      <input disabled={addedCompanies.length >= parseInt(selectedCompaniesNumber)} onChange={handleChange} className={pressedAddCompany && !regComert ?  "border border-accent py-1 px-2" : "border border-gray py-1 px-2"} type="text" name="regComert" id="regComert" value={regComert} />
                      {pressedAddCompany && !regComert  && <InputWarning text='Reg comert necesar'/>}
                  </div>
                  <div className="flex flex-col gap-2 relative">
                      <label className="font-bold ml-2" htmlFor="employees">Nr mediu salariati *</label>
                      <input disabled={addedCompanies.length >= parseInt(selectedCompaniesNumber)} onChange={handleChange} className={pressedAddCompany && !employees ? "border border-accent py-1 px-2" : "border border-gray py-1 px-2"} type="text" name="employees" id="employees" value={employees}/>
                      {pressedAddCompany && !employees  && <InputWarning text='Nr mediu de salariati este necesar'/>}
                  </div>
              </div>
              <div className="flex flex-col gap-2 mt-4 relative">
                  <label className="font-bold ml-2" htmlFor="address">Adresa *</label>
                  <input disabled={addedCompanies.length >= parseInt(selectedCompaniesNumber)} onChange={handleChange} className={pressedAddCompany && !address ? "border border-accent py-1 px-2" : "border border-gray py-1 px-2"} type="text" name="address" id="address" value={address}/>
                  {pressedAddCompany && !address  && <InputWarning text='Adresa este necesara'/>}
              </div>
              <div style={{height: "2px"}} className='bg-accent mt-6'>
                  
              </div>
              <div className="flex justify-between gap-2 mt-4 relative">
                  <div className="flex flex-col gap-2">
                      <label className="font-bold ml-2" htmlFor="judet">Judet raportare *</label>
                      <select disabled={addedCompanies.length >= parseInt(selectedCompaniesNumber)} onChange={handleChange} value={judet} className={pressedAddCompany && !judet ? "border border-accent py-1 px-2" : "border border-gray py-1 px-2"} type="text" name="judet" id="judet">
                          {JUDETE.map(judet => {
                              return <option disabled={judet.id_judet === 0} key={judet.id_judet} value={judet.id_judet}>{judet.judet}</option>
                          })}
                      </select>
                      {pressedAddCompany && !judet  && <InputWarning text='Judetul este necesar'/>}
                  </div>
                  <div className="flex flex-col gap-2 relative">
                      <label className="font-bold ml-2" htmlFor="caen">Cod CAEN raportare *</label>
                      <input disabled={addedCompanies.length >= parseInt(selectedCompaniesNumber)} onChange={handleChange} className={pressedAddCompany && !caen ? "border border-accent py-1 px-2" : "border border-gray py-1 px-2"} type="number" min="1000" max="9999" name="caen" id="caen" value={caen}/>
                      {pressedAddCompany && !caen  && <InputWarning text='Codul CAEN este necesar'/>}
                  </div>
              </div>
              <div className="flex flex-col gap-2 mt-4 relative">
                  <label className="font-bold ml-2" htmlFor="usedApplication">Ce aplicatie folosesti pentru revisal</label>
                  <select disabled={addedCompanies.length >= parseInt(selectedCompaniesNumber)} onChange={handleChange} className="border border-gray py-1 px-2" type="text" name="usedApplication" id="usedApplication" value={usedApplication}>
                      <option value={0} disabled>Selecteaza ce revisal folosesti</option>
                      {usedApplicationOptions.map(application => {
                          return <option key={application.idTip} value={application.idTip}>{application.tipRevisal} - {application.descriereTip}</option>
                      })}
                  </select>
                  {pressedAddCompany && !usedApplication && <InputWarning text='Aplicatia folosita este necesara'/>}
              </div>
              <div className="mt-4 flex justify-end">
                  <button disabled={addedCompanies.length >= parseInt(selectedCompaniesNumber)} type="submit" style={{maxWidth: "300px"}} className='cursor-pointer flex gap-6 items-center text-2xl text-primary2 justify-end'>
                    <p>Valideaza</p>
                    <span style={{fontSize: "50px"}} className="material-icons mt-4">add_box</span>    
                  </button>
                  
              </div>
          </form>
    </div>
  )
}

export default AddCompany