import { useAtom } from "jotai";
import React, { useState, useEffect } from "react";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import { userState } from "../../state";
import CnpExceptionCreate from "./CnpExceptionCreate";
import CnpExceptionsShow from "./CnpExceptionsShow";
import { toast } from "react-toastify";

function CnpExceptions() {
  const [exceptions, setExceptions] = useState([]);

  const axiosPrivate = useAxiosPrivate();
  const [company] = useAtom(userState.currentCompany);

  const getCnpExceptions = async () => {
    try {
      const response = await axiosPrivate.get(
        `/api/cnp-exception?companyId=${company.id}`
      );
      console.log(response);
      setExceptions(response.data);
    } catch (e) {
      console.log(e);
      toast.error("A aparut o eroare, incearca mai tarziu", {
        position: "top-center",
      });
    }
  };

  useEffect(() => {
    getCnpExceptions();
  }, []);

  return (
    <>
      {/* HEADER */}
      <div className='flex gap-6'>
        {/* EXCEPTIONS ADD  */}
        <div>
          <div>
            <p>
              Introduceti CNP-urile care doriti sa fie excluse automat din
              fisierele incarcate.
            </p>
            <p>
              Atentie! Procesul de excludere tine cont de lista de CNP-uri
              completate in momentul incarcarii fisierului.
            </p>
          </div>
          <div className='flex gap-4 mt-8'>
            <div className='bg-primary2 p-3 w-60 text-white h-12'>
              <p>Cnp</p>
            </div>
            <div
              style={{ width: "500px" }}
              className='bg-primary2 p-3 text-white h-12'
            >
              <p>Descriere/ Detalii</p>
            </div>
          </div>
          <CnpExceptionCreate
            getCnpExceptions={getCnpExceptions}
            company={company}
          />
        </div>
        {/* EXCEPTIONS SHOW  */}
        <div>
          <div className='mb-14 pl-20'>
            <p>Lista exceptii active</p>
          </div>
          <div style={{borderLeft: "2px gray solid"}} className="border-lightGray px-20">
            <div
              style={{ width: "500px" }}
              className='bg-primary2 p-3 text-white h-12'
            >
              <p>Descriere/ Detalii</p>
            </div>
            <div
              style={{ height: "630px" }}
              className='active-scroll overflow-auto w-max pr-8'
            >
              {exceptions.map((cnpException) => {
                return (
                  <CnpExceptionsShow
                    key={cnpException.id}
                    getCnpExceptions={getCnpExceptions}
                    description={cnpException.description}
                    id={cnpException.id}
                  />
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
{
  /* SHOW EXCEPTION  */
}
{
  /* <div style={{maxHeight: "530px"}} className='overflow-auto w-max pr-8'>
              {exceptions.map(cnpException => {
               return <CnpExceptionsShow key={cnpException.id} getCnpExceptions={getCnpExceptions} hashedCnp={cnpException.hashedCnp} description={cnpException.description} id={cnpException.id} />
            })}
        </div> */
}

export default CnpExceptions;
