import { useAtom } from "jotai";
import React, { useState, useEffect } from "react";
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
import { subscriptionState } from "../../../state";
import ContinueButtonFirstPage from "./ContinueButtonFirstPage";

const defaulFormFields = {
  companiesNumber: 1,
  employeesNumber: 1000,
  visibilityAddon: false,
  vipAddon: false,
};

function FirstPageInputs() {
  const axiosPrivate = useAxiosPrivate();

  // const [selectedSubscription, setSelectedSubscription] = useAtom(subscriptionState.selectedSubscription);
  const [atomSelectedCompaniesNumber, setSelectedCompaniesNumber] = useAtom(
    subscriptionState.selectedCompaniesNumber
  );
  const [atomEmployeesNumber, setEmployeesNumber] = useAtom(
    subscriptionState.employeesNumber
  );
  const [atomAddon, setAddOn] = useAtom(subscriptionState.addOn);
  const [atomVipAddon, setVipAddon] = useAtom(subscriptionState.vipAddon);

  const [, setFinalPrice] = useAtom(subscriptionState.finalPrice);
  const [, setListPrice] = useAtom(subscriptionState.listPrice);
  const [, setDiscount] = useAtom(subscriptionState.discount);
  const [, setDiscountName] = useAtom(subscriptionState.discountName);

  const [formFields, setFormFields] = useState(defaulFormFields);

  const { companiesNumber, employeesNumber, visibilityAddon, vipAddon } =
    formFields;

  useEffect(() => {
    setFormFields((old) => ({
      ...formFields,
      companiesNumber: atomSelectedCompaniesNumber,
      employeesNumber: atomEmployeesNumber,
      visibilityAddon: atomAddon,
      vipAddon: atomVipAddon,
    }));
  }, []);

  const handleChange = (e) => {
    console.log(e);
    const { name } = e.target;

    const value =
      e.target.type === "checkbox" ? e.target.checked : e.target.value;

    if (name === "companiesNumber") {
      setSelectedCompaniesNumber(value);
    }
    if (name === "employeesNumber") {
      setEmployeesNumber(value);
    }
    if (name === "visibilityAddon") {
      setAddOn(value);
    }
    if (name === "vipAddon") {
      setVipAddon(value);
    }
    console.log(companiesNumber);
    setFormFields((old) => ({ ...formFields, [name]: value }));
  };

  const handleFocusOut = (e) => {
    const { name, value } = e.target;
    console.log(name);
    console.log(value);
    console.log(value <= 0);
    if (value <= 0) {
      if (name === "companiesNumber") {
        setSelectedCompaniesNumber(1);
      }
      if (name === "employeesNumber") {
        setEmployeesNumber(1);
      }
      setFormFields((old) => ({ ...formFields, [name]: 1 }));
    }

   
  };

  useEffect(() => {
    getPricing();
  }, [formFields]);

  const getPricing = async () => {
    const response = await axiosPrivate.get(
      `/api/view-subscription-pricing?companiesNumber=${atomSelectedCompaniesNumber}&employeesNumber=${atomEmployeesNumber}&visibilityAddon=${
        atomAddon ? 1 : 0
      }&vipAddon=${vipAddon ? 1 : 0}`
    );
    console.log(response.data);
    const { data } = response;
    setFinalPrice(data.pretFinal);
    setListPrice(data.pretLista);
    setDiscount(data.discount);
    setDiscountName(data.numeDiscount);
  };

  const [, setSelectedPage] = useAtom(subscriptionState.selectedPage);

  const handleSubmit = (e) => {
    e.preventDefault();
    setSelectedPage(2);
  };

  return (
    <form className='mt-8 flex flex-col gap-6' onSubmit={handleSubmit}>
      <div className='flex gap-2'>
        <div className='bg-green text-white p-3 w-56'>Nr companii grup</div>
        <input
          className='block text-green border-gray border p-3'
          type='number'
          name='companiesNumber'
          value={companiesNumber}
          onChange={handleChange}
          onBlur={handleFocusOut}
          min='1'
        />
      </div>
      <div className='flex gap-2'>
        <div className='bg-green text-white p-3 w-56'>Nr total angajati</div>
        <input
          className='block text-green border-gray border p-3'
          type='number'
          name='employeesNumber'
          value={employeesNumber}
          onChange={handleChange}
          onBlur={handleFocusOut}
          min='1'
        />
      </div>
      <div className='text-gray flex gap-64'>
        <div>
          <div className='flex gap-4'>
            <div>
              <p className='font-bold'>Add on EXTRA Visibility</p>
              <p>
                Companie ta se extinde si esti interesat de indicatori pentru
                functii noi?
              </p>
            </div>
            <div>
              <input
                className='w-8 h-8'
                type='checkbox'
                name='visibilityAddon'
                checked={visibilityAddon}
                onChange={handleChange}
              />
            </div>
          </div>
          <div className='flex items-center gap-2 mt-8'>
            <span className='material-icons'>check</span>
            <span>
              Acces la indicatori benchmarking pentru toate functiile cor: 2 ani
              (2022 &amp; 2023)
            </span>
          </div>
        </div>
        <div className='flex gap-4'>
          <div className='text-justify'>
            <p className='font-bold'>Add on VIP</p>
            <p className='flex'>
              <span className='material-icons'>check</span>
              <span>
                4 ore de astistena personalizata pentru tine si echipa ta
              </span>
            </p>
            <p className='flex'>
              <span className='material-icons'>check</span>
              <span>
                Prioritate in implementarea de indicatori si functionalitati noi
              </span>
            </p>
            <p className='flex'>
              <span className='material-icons'>check</span>
              <span>
                15% extra discount la toate add-on-urile promovate in perioada
                de valabilitate a abonamentului
              </span>
            </p>
          </div>
          <div>
            <input
              className='w-8 h-8'
              type='checkbox'
              name='vipAddon'
              checked={vipAddon}
              onChange={handleChange}
            />
          </div>
        </div>
      </div>
      <div className='absolute right-24 bottom-8'>
        <ContinueButtonFirstPage />
      </div>
    </form>
  );
}

export default FirstPageInputs;
