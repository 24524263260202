import textTactiqHr from '../../images/textTactiqHr.png'
import logoSimple from '../../images/logos/logoSimple.png'

import { Outlet, Link } from "react-router-dom";

import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import { useEffect } from "react";
import { userState } from "../../state";
import { useAtom } from "jotai";

import Dropdown from "react-dropdown";
import "react-dropdown/style.css";
import state from "../../state";

import { useNavigate } from "react-router-dom";
import NavigationDropdown from "../NavigationDropdown";
import { useState } from "react";

let companiesNames = [];
let companies = [];

function Navigation() {
  const [token, setToken] = useAtom(state.token);
  const navigate = useNavigate();

  const setLogout = () => {
    setToken("");
  };

  const [activeDropdown, setActiveDropdown] = useState(false);

  const axiosPrivate = useAxiosPrivate();

  const [user, setUser] = useAtom(userState.user);
  const [userExtendedAccess, setUserExtendedAcces] = useAtom(userState.extendedAccess);
  const [currentCompany, setCurrentCompany] = useAtom(userState.currentCompany);

  const changeCurrentCompany = (e) => {
    let cc = companies.find((company) => company.denumireCompanie === e.value);
    setCurrentCompany(cc);
    console.log(cc);
  };

  const getData = async () => {
    const controller = new AbortController();
    try {
      await Promise.all([
        axiosPrivate.get(`/getUser`, {
          signal: controller.signal,
        }),
        axiosPrivate.get("/api/get-user-companies"),
        axiosPrivate.get("/api/extended-access"),
      ]).then((response) => {
        setUser(response[0].data);
        console.log(response[1].data);
        companies = response[1].data.sort(
          (a, b) => Number(a.demoCompany) - Number(b.demoCompany)
        );
        if (!currentCompany) {
          setCurrentCompany(response[1].data[0]);
        }
        companiesNames = companies?.map((company) => company.denumireCompanie);
        console.log(response[2].data)
        setUserExtendedAcces(response[2].data);
      });
    } catch (err) {
      console.error(err);
      companies = [];
      companiesNames = [];
      setCurrentCompany("");
    }
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <>
      <div className='h-full fixed left-0 top-0 z-10 bg-primary2'>
        <div style={{ width: "75px" }}>
          {/* TODO mt-10 for navbar */}
          <ul className='flex flex-col justify-center items-center mt-4 gap-24'>
            <li style={{display: 'block', height: '55px', width: '60px'}} className='flex flex-col justify-center items-center'>
              <span style={{ display: 'block', height: '45px', width: '46px', backgroundImage: `url(${logoSimple})` }} className='bg-cover bg-center mx-auto'></span>
              {/* <div style={{marginBottom: "50px"}}></div> */}
              <span style={{marginLeft: '5px'}} className='material-icons md-48 text-white block'>menu</span>
            </li>
            <li style={{cursor: currentCompany.id > 1000000 ? "not-allowed" : "pointer"}} title="Incarca Date">
              <Link style={{pointerEvents: currentCompany.id > 1000000 ? 'none' : ''}} to='/upload'>
                <span className='material-icons md-48 text-white'>
                  file_upload
                </span>
              </Link>
            </li>
            <li title="Vezi Indicatorii Tai">
              <Link to='/kpi'>
                <span className='material-icons md-48 text-white'>
                  stacked_bar_chart
                </span>
              </Link>
            </li>
            <li title="Benchmarking">
              <Link to='/bench'>
                <span className='material-icons md-48 text-white'>
                  switch_access_shortcut_add
                </span>
              </Link>
            </li>
            <li title="Abonamentele Tale">
              <Link to='/subscriptions'>
                <span className='material-icons md-48 text-white'>
                  add_business
                </span>
              </Link>
            </li>
            <li title='tichete'>
              <Link to='/tickets'>
                <span className='material-icons md-48 text-green'>
                  contact_support
                </span>
              </Link>
            </li>
            <li title='functiile tale'>
              <Link to='/your-functions'>
                <span className='material-icons md-48 text-white'>account_tree</span>
              </Link>
            </li>
            {userExtendedAccess &&
              <li title="Indicatori Extinsi">
              <Link to='/extended-bench'>
                <span className='material-icons md-48 text-yellow'>
                  star
                </span>
              </Link>
            </li>
            }
            
          </ul>
        </div>
      </div>
      <nav className='absolute top-0 right-0  w-full'>
        <div style={{ height: "100px" }} className='bg-lightGray px-10'>
          <div
            style={{ marginLeft: "75px" }}
            className='h-full flex justify-between items-center'
          >
            {/* LOGO  */}
            <div className='text-3xl text-primary2'>
              <Link to='/' style={{display: "block", height: '50px', width: "425px", backgroundImage: `url(${textTactiqHr})`}} className='bg-cover bg-center'>
              </Link>
            </div>
            <ul className='flex justify-between items-center gap-10 text-xl'>
              <li>
                <Dropdown
                  onChange={changeCurrentCompany}
                  options={companiesNames}
                  value={
                    currentCompany
                      ? currentCompany.denumireCompanie
                      : companiesNames[0]
                  }
                />
              </li>
              <li className='flex items-center gap-2 text-primary2'>
                {user.firstName} {user.lastName} {"  "}
                {/* <span
                  onClick={() => {
                    setLogout();
                    setCurrentCompany("");
                    localStorage.clear();
                    navigate("/login");
                  }}
                  style={{ color: "rgba(0, 0, 0, 0.54)" }}
                  className='material-icons md-48'
                >
                  person
                </span> */}
                <span
                  className='material-icons md-48 relative cursor-pointer text-black'
                  onMouseOver={() => setActiveDropdown(true)}
                  onMouseOut={(e) => {
                    if (e.target.className !== "nav-dropdown") {
                      setActiveDropdown(false);
                    }
                  }}
                >
                  person {activeDropdown && <NavigationDropdown />}
                </span>
              </li>
            </ul>
          </div>
        </div>
      </nav>
      <div className=' h-full w-full'>
        <div
          style={{ top: "100px", backgroundSize: "100% auto", minHeight: "90vh", marginLeft: "75px", width: "96.1%" }}
          className='absolute bg-homeBackgroundImage bg-no-repeat'
        ></div>
        <div className='mt-32 bg-white'>
          <Outlet />
        </div>
      </div>
    </>
  );
}

export default Navigation;
