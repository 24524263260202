import { useAtom } from "jotai";
import React from "react";
import { subscriptionState } from "../../../state";

function PricingFirstPage() {
  const [listPrice] = useAtom(subscriptionState.listPrice);
  const [discount] = useAtom(subscriptionState.discount);
  const [finalPrice] = useAtom(subscriptionState.finalPrice);
  const [discountName] = useAtom(subscriptionState.discountName);

  return (
    <div className='text-2xl mt-16 leading-10 w-max'>
      <div className='flex gap-6'>
        <p className='font-bold'>Pret abonament:</p>
        <p className='text-gray'>{listPrice} Eur + TVA</p>
      </div>
      {discount !== 0 && (
        <div className='flex gap-6'>
          <p className='font-bold'>{discountName}:</p>
          <p className='text-gray'>{discount}%</p>
        </div>
      )}
      <div style={{ height: "2px", backgroundColor: "gray" }}></div>
      <div className='flex gap-6'>
        <p className='font-bold text-3xl'>Pret final:</p>
        <p style={{ marginLeft: "60px" }} className='text-gray'>
          {finalPrice} Eur + TVA
        </p>
      </div>
    </div>
  );
}

export default PricingFirstPage;
