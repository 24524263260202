import { useAtom } from "jotai";
import { useState } from "react";
import { userState } from "../../state";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import { toast } from "react-toastify";
import { useEffect } from "react";

function CalculateKpi({ selectedYear }) {
  const [user] = useAtom(userState.user);
  const [company] = useAtom(userState.currentCompany);
  const axiosPrivate = useAxiosPrivate();

  const [lastUpdate, setLastUpdate] = useState("");
  const [ongoingCalculation, setOngoingCalculation] = useState(false);

  const calculateKpiFunction = async () => {
    //todo complete function fetch
    //todo sa il blochez sa nu mai dea click pana nu se termina procedura
    setOngoingCalculation(true);
    try {
      toast.info("Calculul a fost demarat. Vei primi un email de confirmare la finalizarea procesului. Data finalizarii ultimului calcul va fi afisata si in interfata", {
        position: "top-center",
      });
      const response = await axiosPrivate.get(
        `/api/calculate-kpi?cui=${company.cif}&year=${selectedYear}&userId=${user.id}`
      );
      console.log(response);
      getLastKpiUpdate();
      // toast.success("Procedura executata cu succes", { position: "top-center" });
    } catch (e) {
      console.log(e);
      // toast.error("A aparut o eroare la rularea procedurii", {
      //   position: "top-center",
      // });\
      console.log(e);
    }
    finally {
      // toast.success("Procedura executata cu succes", { position: "top-center" });
      setOngoingCalculation(false);
    }
  };

  const getLastKpiUpdate = async () => {
    try {
      const response = await axiosPrivate.get(
        `/api/last-kpi-update?cui=${company.cif}`
      );
      console.log(response);
      if (!response.data) {
        setLastUpdate("Nu exista inregistrari");
        return;
      }
      const data = new Date(response.data);
      console.log(data);
      data.setTime(data.getTime() + 10 * 60 * 60 * 1000);
      setLastUpdate(
        data.toLocaleDateString("RO-ro", {
          day: "numeric",
          month: "long",
          year: "numeric",
          hour: "numeric",
          minute: "numeric",
        })
      );
    } catch (e) {
      console.log(e);
      console.log("Eroare la aducere data ultim calcul");
    }
  };

  useEffect(() => {
    getLastKpiUpdate();
  }, [company]);

  return (
    <div className='flex items-center gap-2 pr-16 ml-32'>
      <div style={{ maxWidth: "150px" }} className='text-right text-base'>
        <p>Ultimul calcul</p>
      </div>
      <div className='border-2 p-2 mt-1'>
        <p>{lastUpdate}</p>
      </div>
      <div>
        <button
          disabled={ongoingCalculation}
          style={{ fontSize: "52px", cursor: ongoingCalculation ? "not-allowed" : "pointer" }}
          onClick={calculateKpiFunction}
          className='material-icons text-primary2 mt-4 cursor-pointer'
        >
          play_circle
        </button>
      </div>
    </div>
  );
}

export default CalculateKpi;
