function ImageCard({ image }) {
  return (
    <div
      style={{
        height: "400px",
        width: "550px",
        zIndex: "1",
        backgroundImage: `url(${image})`,
      }}
      className='shadow-lg'
    >
      {/* <div style={{zIndex: '-1'}} className="bg-imageCard h-full w-full bg-cover bg-center"></div> */}
      <div
        style={{ marginTop: "70px" }}
        className='py-8 flex flex-col justify-center items-center w-full font-bold pr-28'
      >
        <div className='p-4 w-full h-48'>
          <h1 className='py-2 px-1 text-3xl text-gray font-black'>News</h1>
          <div className='leading-7'>
            <p className='px-1 mt-1 text-green block'>
              <span className='font-extrabold'>01.09.2022 - Release:</span>{" "} <br />
              Introducere standard ISCO 8
            </p>
            <p className='px-1 mt-1 text-primary2 block'>
              <span className='font-extrabold'>
                01.03.2023 - Lansare <br /> TactiqHR Sunshine
              </span>
              - Galeria de prezentari
            </p>
          </div>
        </div>
        <div className='p-4 w-full h-48'>
          <h1 className='py-2 px-1 text-xl text-gray'>
            Mentenanta planificata
          </h1>
          <div className='leading-7'>
            <p className='px-1 mt-1 text-accent'>
              06.02.2023 - in intervalul orar 09:00-14:00
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ImageCard;
