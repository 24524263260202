import { useField, ErrorMessage } from "formik"

function CheckboxField({ label, ...props }) {

    const [field, meta] = useField(props);
    // console.log(field.checked != undefined)
    let className = (field.checked === undefined) ? 'w-4 h-4' : '';

  return (
        <div className="mr-2 inline">
            <input type="checkbox"
                className={`${className} ${meta.touched && meta.error && 'invalid-input'}`}
            {...field} {...props}
            />
            <ErrorMessage component="div" className="error" name={ field.name }/>
        </div>
    )
}

export default CheckboxField