import { useAtom } from "jotai";
import { subscriptionState } from "../../../state";

function BackButton({ gotoPage }) {
  
  const [, setSelectedPage] = useAtom(subscriptionState.selectedPage);
  const [, setAddedCompanies] = useAtom(subscriptionState.addedCompanies);
  const [, setMainCompany] = useAtom(subscriptionState.mainCompany);

  const handleBackButton = () => {
    setSelectedPage(gotoPage);
    if (gotoPage === 1) {
      setAddedCompanies([]);
      setMainCompany("")
    }
  }

  return (
    <button style={{fontSize: "48px"}} onClick={handleBackButton} className='material-icons absolute top-4 right-4'>
          backspace
    </button>
  )
}

export default BackButton