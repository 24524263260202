export function saveAsXlsxFile(base64Str, filename){
      var mediaType="data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,";
      var a = document.createElement('a');
      a.href = mediaType+base64Str
      //a.href = mediaType+userInp;
      a.download = filename;
      a.textContent = 'Download file!';
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a)
      window.URL.revokeObjectURL(a)
}
    
export function fileToByteString(file) {
  return new Promise((resolve, reject) => {
      const reader = new FileReader();

      reader.onload = (event) => {
        resolve(event.target.result.slice(22));
      };

      reader.onerror = (err) => {
        reject(err);
      };
      // reader.readAsBinaryString(file);
    reader.readAsDataURL(file);
    });
}