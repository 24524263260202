import React from "react";

function RegisterFinish() {
  return (
    <div className="text-center">
          <h1 className="text-3xl font-bold">Multumim pentru inregistrare.</h1>
          <p className="text-2xl">Codul de activare a fost trimis pe adresa de email.</p>
    </div>
  );
}

export default RegisterFinish;
