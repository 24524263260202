import LoginForm from "./login/LoginForm";
import { Link } from "react-router-dom";
import Api from "../../utilities/Api";
import { toast } from "react-toastify";
import { useAtom } from "jotai";
import { state } from "../../state";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import LoginOtp from "./login/LoginOtp";
import { useEffect } from "react";

function LoginFormPage() {
  const [, setToken] = useAtom(state.token);
  const [usedEmail, setUsedEmail] = useState("");
  const [onOtpPage, setOnOtpPage] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    return () => {
      setOnOtpPage(false);
    };
  }, []);

  return (
    <div>
      <div className='text-black'>
        {!onOtpPage ? (
          <div className='w-full bg-white py-6 px-8 text-gray'>
            <h3 className='text-center text-2xl'>Login</h3>
            <p className='text-center mb-12 text-2xl'>(Utilizator existent)</p>
            <LoginForm
              setOnOtpPage={setOnOtpPage}
              setUsedEmail={setUsedEmail}
              usedEmail={usedEmail}
            />
          </div>
        ) : (
          <div className='w-full bg-white py-6 px-8 text-center'>
            <h2 className='font-bold'>
              Introdu codul primit pe adresa de email
            </h2>
            <p>(Codul are valabilitate 3 minute)</p>
            <LoginOtp usedEmail={usedEmail} setOnOtpPage={setOnOtpPage} />
          </div>
        )}
        <Link to={"/register"}>
          <div className='bg-lightGray py-6 px-12 relative text-gray'>
            <div className="font-bold text-xl">
              <p>Nu ai inca cont pentru compania ta?</p>
              <p>Inregistreaza-te GRATUIT pentru acces cont demo</p>
            </div>
            
            <div className='text-gray mt-4'>
              <p>
                Din doar cateva click-uri te vei convinge cat de usor de
                utilizat si cat de util este produsul si poti activa oricand
                functionalitatile complete:
              </p>
              <ul className='list-disc ml-4 mt-8'>
                <li>Peste 100 indicatori HR disponibili on click</li>
                <li>Optiune actualizare lunara si vizualizare istoric</li>
                <li>Acces permanent la rapoarte per industrie, locatie etc.</li>
              </ul>
            </div>
            <div className='absolute top-32 right-2'>
              <span style={{fontSize: "60px"}} className='material-icons text-primary2 hover:cursor-pointer p-2 text-7xl'>
                keyboard_double_arrow_right
              </span>
            </div>
          </div>
        </Link>
      </div>
    </div>
  );
}

export default LoginFormPage;
