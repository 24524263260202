import axios from 'axios'

// TODO chage base_url to host_base_url when pushing to azure
// const BASE_URL = "http://localhost:8080/";
const BASE_URL = "https://demo-server.tactiqhrinsights.ro/";
// const BASE_URL = "https://app-server.tactiqhrinsights.ro/";

export default axios.create(
    {baseURL: BASE_URL}
)

export const axiosPrivate = axios.create({
  baseURL: BASE_URL,
  headers: { "Content-Type": "application/json" },
  withCredentials: false,
});