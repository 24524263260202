import { useAtom } from "jotai";
import { useState } from "react";
import { toast } from "react-toastify";
import { subscriptionState } from "../../../state";


function ShowCompanies() {

    const [addedCompanies, setAddedCompanies] = useAtom(subscriptionState.addedCompanies);

    const [mainCompany, setMainCompany] = useAtom(subscriptionState.mainCompany);
    // console.log("AICICICICICI")
    // console.log(mainCompany)

    const [currentSelectedRadio, setCurrentSelectedRadio] = useAtom(subscriptionState.currentSelectedRadio);

    const handleChange = (company, i) => {
        setMainCompany(company);
        setCurrentSelectedRadio(i);
        console.log(mainCompany);
    }

    console.log("AICAIICICICIC")
    console.log(mainCompany);

  const handleRemove = (company) => {
    if (company.cif === mainCompany.cif) {
      toast.warn("Nu poti sterge compania main", {position: "top-center"});
      return;
      }
        setAddedCompanies(oldValue => {
            return oldValue.filter(obj => obj.cif !== company.cif)
        })
    }

  return (
    <div className='mt-16'>
      <div className='flex gap-32 text-xl font-bold'>
        <p>CIF</p>
        <p className='w-24'>Companie facturare</p>
      </div>
      <div
        style={{ height: "350px" }}
        className='overflow-auto active-scroll'
      >
        {addedCompanies.map((company, i) => (
          <div key={company.cif} className='flex gap-16 mb-6'>
            <div className='bg-lightGray p-3 w-32 border border-gray'>
              {company.cif}
            </div>
            <input onChange={() => handleChange(company, i)} checked={currentSelectedRadio === i}  className='w-12' type='radio' name='main-company'  />
            <span
              style={{ fontSize: "52px" }}
              onClick={() => handleRemove(company)}
              className='material-icons text-accent cursor-pointer'
            >
              remove
            </span>
          </div>
        ))}
      </div>
    </div>
  );
}

export default ShowCompanies