import UploadModalButton from "./UploadModalButton"
const romanianMonths = ['Ianuarie', 'Februarie', 'Martie', 'Aprilie', 'Mai', 'Iunie', 'Iulie', 'August', 'Septembrie', 'Octombrie', 'Noiembrie', 'Decembrie'];

function ReUploadModal({ dateToSend, setIsOpen, getCompanyRvsUploadDates, getCompanyD112UploadDates, getCompanyStatUploadDates ,fileType }) {
  
  const headerMonth = romanianMonths[dateToSend.getMonth()] + " " + dateToSend.getFullYear()
  let apiUrl;
  if (fileType === "rvs") {
    apiUrl = "api/upload-revisal"
  } else if (fileType === "d112") {
    apiUrl = "/api/upload-d112"
  } else if (fileType === "stat") {
    apiUrl = "/api/stat"
  } 

  return (
     <div>
          <div className='bg-primary2 text-white w-full h-28 text-center flex items-center px-14'>
              {/* Header Part  */}
              <div className='flex justify-between w-full text-3xl'>
                  <p>Ati selectat actiunea: Reincarca fisier {fileType === "rvs" ? "Revisal" : fileType === "d112" ? "D112" : "Stat"}  corespunzator lunii {headerMonth} </p>
                  <span onClick={() => setIsOpen(false)} className="material-icons cursor-pointer md-48">cancel</span>
              </div>
          </div>
      <div className='px-14 mt-8 text-2xl text-primary2'>
        {fileType === "rvs" && <div>
          <p>Recomandari/ Instructiuni:</p>
          <ul className='list-disc'>
            <li className='ml-7'>Datele aferente lunii selectate vor fi inlocuite cu datele aferente operatiunii curente</li>
            <li className='ml-7'>In cazul in care ati optat pentru un abonament de tip grup asigurati-va ca fisierul pe care doriti sa il reincarcati corespunde companiei afisate in meniu</li>
            <li className='ml-7'>In situatia in care luna urmatoare a fost initializata prin operatiunea Raporteaza luna fara evenimente este recomandat sa o reinitializati si pe aceasta sau sa incarcati fisierul .rvs corespunzator (lunile initializate prin copiere sunt evidentiate astfel: contin “(C)” in mesaj)</li>
            <li className='ml-7'>Asigurati-va ca fisierul incarcat include toate contractele incetate in cursul lunii</li>
            <li className='ml-7'>Daca folositi aplicatia Revisal HG905/2017 pentru generarea fisierelor nu sunt necesare operatiuni suplimentare </li>
            <li className='ml-7'>In situatia in care pentru generarea fisierelor revisal folositi alta aplicatie si in mod uzual contractele incetate sunt incluse in raportare doar pana la data incetarii recomandam sa regenerati fisierul cu includerea tuturor contractelor devenite inactive in luna, sa il validati prin intermediul aplicatiei Revisal HG905/2017 si sa il incarcati in aceasta forma.</li>
            <li className='ml-7'>CNP-urile introduse in sectiunea Configureaza exceptii nu vor fi procesate</li>
            <li className='ml-7'>In situatia in care in luna selectata nu au fost inregistrare evenimente puteti utiliza functionalitatea Raporteaza luna fara evenimente</li>
            <li className='ml-7'>La finalizarea procesului veti primi un mesaj de confirmare iar statusul se va actualiza automat cu indicarea datei si orei la care s-a finalizat procesul</li>
          </ul>
          <p className="mt-12">Calculul indicatorilor va fi actualizat automat la finalul fiecarei zile <br /> Pentru a rula calculul in avans, accesati pagina Indicatorii tai – Calcul
          </p>
        </div>}
        {fileType === "d112" &&  <div>
            <ul className="list-disc">
            <li className="ml-7">Datele aferente lunii selectate vor fi inlocuite cu datele aferente operatiunii curente</li>
            <li className="ml-7">In cazul in care ati optat pentru un abonament de tip grup asigurati-va ca fisierul pe care doriti sa il reincarcati corespunde companiei afisate in meniu</li>
            <li className="ml-7">Incarcati fisierul cu extensia .xml atasat declaratiei unice corespunzatoare lunii</li>
            <li className="ml-7">CNP-urile introduse in sectiunea Configureaza exceptii nu vor fi procesate</li>
            <li className="ml-7">La finalizarea procesului veti primi un mesaj de confirmare iar statusul se va actualiza automat cu indicarea datei si orei la care s-a finalizat procesul</li>
          </ul>
          <p className="mt-12">Calculul indicatorilor va fi actualizat automat la finalul fiecarei zile. <br /> Pentru a rula calculul in avans, accesati pagina Indicatorii tai - Calcul </p>
        </div>} 
              

              <UploadModalButton dateToSend={dateToSend} getCompanyRvsUploadDates={getCompanyRvsUploadDates} getCompanyD112UploadDates={getCompanyD112UploadDates} getCompanyStatUploadDates={getCompanyStatUploadDates} apiUrl={apiUrl} fileType={fileType} />

        </div>
    </div>
  )
}

export default ReUploadModal