import { Outlet } from "react-router-dom";
import logoRegister from "../images/logos/logoRegister.png";

function RegisterLogin({ setToken }) {
  return (
    <div className='flex w-full min-h-screen'>
      <div className='w-full md:w-2/4 bg-primary2 text-white flex justify-center items-center px-20'>
        <div>
          <div
            style={{
              width: "636px",
              height: "310px",
              backgroundImage: `url(${logoRegister})`,
            }}
            className='text-center text-2xl flex items-center justify-center mx-auto mb-4'
          >
            {/* Tactiq{" "}
            <span
              style={{ color: "#00b050" }}
              className='material-icons md-48 mx-2'
            >
              speed
            </span>
            hr insights */}
          </div>
          <Outlet />
        </div>
      </div>

      <div
        style={{ backgroundPosition: "100% 0%" }}
        className='w-2/4 bg-registerBackgroundImage  bg-cover  justify-center items-center px-32 hidden md:flex'
      >
        <div className='py-8 flex flex-col justify-center items-center bg-white w-full mt-28 font-bold'>
          <div className='border-b border-lightGray p-4 w-full h-48'>
            <h1 className='bg-lightGray py-2 px-1 text-xl'>News</h1>
            <div className='leading-7'>
              <p className='px-1 mt-1 text-green block'>
                01.09.2022 - Release: Introducere standard ISCO 8
              </p>
              <p className='px-1 mt-1 text-primary2 block'>
                01.03.2023 - Lansare TactiqHR Sunshine - Galeria de prezentari
              </p>
            </div>
          </div>
          <div className='p-4 w-full border-t h-48'>
            <h1 className='bg-lightGray py-2 px-1 text-xl'>
              Mentenanta planificata
            </h1>
            <div className='leading-7'>
              <p className='px-1 mt-1 text-accent'>
                06.02.2023 - in intervalul orar 09:00-14:00
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default RegisterLogin;
