import {BrowserRouter as Router, Route, Routes} from "react-router-dom";
import RegisterLogin from "./pages/RegisterLogin";
import ErrorPage from "./pages/ErrorPage";
import {ToastContainer} from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import LoginFormPage from "./components/form-pages/LoginFormPage";
import RegisterFormPage from "./components/form-pages/RegisterFormPage";
import ResetPasswordPage from "./pages/ResetPasswordPage";
import HomePage2 from "./pages/HomePage2";
import Navigation from "./components/navigation/Navigation";
import UploadPage from "./pages/UploadPage";
import PrivateRoute from "./pages/PrivateRoute";
// import KpiPage from "./pages/KpiPage";
import GeneralBenchPage from "./pages/GeneralBenchPage";
import SubscriptionsPage from "./pages/SubscriptionsPage";
import RegisterVerification from "./pages/RegisterVerification";
import TicketsPage from "./pages/TicketsPage";
import YourFunctionsPage from "./pages/YourFunctionsPage";
// import ExtendedBenchPage from "./pages/ExtendedBenchPage";

function App() {


    return (
        <div id='app' className='font-montserrat'>
            <Router>
                <Routes>
                    <Route element={<RegisterLogin/>}>
                        <Route path='/register' element={<RegisterFormPage/>}/>
                        <Route path='/login' element={<LoginFormPage/>}/>
                    </Route>

                    <Route element={<Navigation/>}>
                        <Route exact path='/' element={<PrivateRoute/>}>
                            <Route exact path='/' element={<HomePage2/>}/>
                            <Route path='/upload' element={<UploadPage/>}/>
                            <Route path="/kpi"             element={<GeneralBenchPage pageType={"kpiPage"} isDoubleColumn={false}/>}/>
                            <Route path="/bench"           element={<GeneralBenchPage pageType={"benchPage"} isDoubleColumn={true}/>}/>
                            <Route path="/extended-bench"  element={<GeneralBenchPage pageType={"exbenchPage"} isDoubleColumn={false}/>}/>
                            <Route path="/subscriptions" element={<SubscriptionsPage />} />
                            <Route path="/tickets" element={<TicketsPage />} />
                            <Route path="/your-functions"   element={<YourFunctionsPage/>}/>
                        </Route>
                    </Route>
                    <Route path="/verify" element={<RegisterVerification />} />
                    <Route path='/reset' element={<ResetPasswordPage/>}/>
                    <Route path='*' element={<ErrorPage/>}/>
                </Routes>
            </Router>
            <ToastContainer/>
        </div>

    );
}

export default App;
