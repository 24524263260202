import { useState } from "react";
import ContinueButtonThirdPage from "./ContinueButtonThirdPage";
import { toast } from "react-toastify";
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
import { useAtom } from "jotai";
import { subscriptionState, userState } from "../../../state";
import InputWarning from "../common/InputWarning";
import { useNavigate } from "react-router-dom";


const defaultFormFields = {
  name: "",
  position: "",
  iban: "",
  termsConditions: false,
};

function InvoiceForm() {

  const axiosPrivate = useAxiosPrivate();
  const navigate = useNavigate();

  const [formFields, setFormFields] = useState(defaultFormFields);
  const [formSubmitted, setFormSubmitted] = useState(false);
    
  const { name, position, iban, termsConditions } = formFields;

  const handleChange = (e) => {
    const { name } = e.target;
    const value =
      e.target.type === "checkbox" ? e.target.checked : e.target.value;
    console.log(formFields);
    setFormFields({ ...formFields, [name]: value });
  };
    
    // lista de companii
    const [addedCompanies] = useAtom(subscriptionState.addedCompanies);
    const [mainCompany] = useAtom(subscriptionState.mainCompany);
    const [employeesNumber] = useAtom(subscriptionState.employeesNumber);
    const [visibilityAddon] = useAtom(subscriptionState.addOn);
    const [vipAddon] = useAtom(subscriptionState.vipAddon);
    const [user] = useAtom(userState.user);
    const [selectedSubscription] = useAtom(subscriptionState.selectedSubscription)
    const [finalPrice] = useAtom(subscriptionState.finalPrice)
    
  const handleSubmit = async (e) => {
      e.preventDefault();
      setFormSubmitted(true);
      for (const [key, value] of Object.entries(formFields)) {
            console.log(`${key}: ${value}`);
            if (!value) {
                toast.warn("Va rugam sa completati toate campurile", { position: "top-center" });
                return;
            }
        }
        const composingObject = {
          companies: addedCompanies,
          mainCompany: mainCompany,
          subscriptionDetails: {
            companiesNumber: addedCompanies.length,
            employeesNumber: employeesNumber,
            visibilityAddon: visibilityAddon ? "1" : "0",
            vipAddon: vipAddon ? "1" : "0",
          },
          contract: {
            email: user.email,
            subscriptionName: selectedSubscription,
            name: name,
            position: position,
            iban: iban,
            finalPrice: finalPrice
          },
        };
        toast.success("Am trimis");
        navigate("/");
        try {
            const response = await axiosPrivate.post("/api/register-companies", composingObject)
          console.log(response);
          setFormSubmitted(false);
        } catch (e) {
          if (e.response.status === 406) {
            toast.error("Pretul nu coincide calculului", { position: "top-center" });
            }
            console.log(e);
        }
        
    }

  return (
    <form style={{ marginTop: "86px" }} className='text-xl flex-1' onSubmit={handleSubmit}>
      <div className='flex flex-col gap-2 relative'>
        <label className='font-bold' htmlFor='name'>
          Nume Prenume Reprezentat Legal
        </label>
        <input
          className={formSubmitted && !name? 'border border-accent py-1 px-2' : 'border border-gray py-1 px-2'}
          id='name'
          type='text'
          name='name'
          value={name}
          onChange={handleChange}
        />
        {formSubmitted && !name && <InputWarning text='Numele reprezentatului este necesar'/>}
      </div>
      <div className='flex flex-col gap-2 mt-4 relative'>
        <label className='font-bold' htmlFor='name'>
          Functie Reprezentant Legal
        </label>
        <input
          className={formSubmitted && !position ? 'border border-accent py-1 px-2' : 'border border-gray py-1 px-2'}
          type='text'
          id='position'
          name='position'
          value={position}
          onChange={handleChange}
        />
        {formSubmitted && !position && <InputWarning text='Functia reprezentatului este necesar'/>}
      </div>
      <div className='flex flex-col gap-2 mt-4 relative'>
        <label className='font-bold' htmlFor='name'>
          IBAN
        </label>
        <input
          className={formSubmitted && !iban ? 'border border-accent py-1 px-2' : 'border border-gray py-1 px-2'}
          type='text'
          id='iban'
          name='iban'
          value={iban}
          onChange={handleChange}
        />
        {formSubmitted && !iban && <InputWarning text='IBAN-ul este necesar'/>}
      </div>
      <div className="flex content-center gap-4 mt-6 relative">
        <input className='w-8 h-8' type="checkbox" name="termsConditions" checked={termsConditions} onChange={handleChange} />
        <label className="text-lg">Sunt de acord cu termenii si conditiile</label>
        {formSubmitted && !termsConditions && <InputWarning text='Va rugam sa fiti de acord termenii si conditiile'/>}
      </div>
      <div className='mt-12'>
        <p className='text-base text-gray'>
          Orice comanda confirmata prin bifarea casutei "Sunt de acord cu termenii si conditiile" reprezinta o acceptare din partea dvs. a ofertei Presatorului, in conditiile stipulate de art. 9 din Legea comertului electronic 365/2002. Bifarea casutei reprezinta o semnatura electronica, in sensul art. 4 pct. 3 din Legea semnaturii electronice nr. 455/2001, avand aceeasi valoare cu o semnatura olografa.
        </p>
        <div className='flex justify-end mt-4'>
          <ContinueButtonThirdPage clicked={formSubmitted} />
        </div>
      </div>
    </form>
  );
}

export default InvoiceForm;
