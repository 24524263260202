import { useAtom } from "jotai";
import { ticketsState } from "../../../state";
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
import { saveAsXlsxFile } from "../../../utilities/FileHandler";
import { toast } from "react-toastify";

function TicketDetailsConversation() {

  const axiosPrivate = useAxiosPrivate();
  const [currentTicketConversation] = useAtom(
    ticketsState.currentTicketConversation
  );
  console.log(currentTicketConversation);
  
  const getImage = async(e) => {
    try {
        console.log(e.target.id)
        const response = await axiosPrivate.get(`/activity-image?activityId=${e.target.id}`);
      console.log(response.data);
      if (response.data.atasament) {
        saveAsXlsxFile(response.data.atasament, response.data.numeFisier);
      } else {
        toast.error("Nu exista niciun fisier atasat", { position: "top-center" });
      }
        
      } catch (e) {
        console.log(e)
      }
  }

  return currentTicketConversation.map((conv) => {
    console.log(conv);
    return (
      <div className="w-2/4" style={{marginLeft: conv.isAdmin && "auto"}}>
        <div className='flex gap-2' style={{color: conv.isAdmin ?  "green" : "red"}}>
          <p>{conv.userName}</p>
          <p>{new Date(conv.timestamp).toLocaleString()}</p>
          <p>{conv.status}</p>
            </div>
        <div className="bg-lightGray p-3 h-32 overflow-auto">{conv.continut}</div>
        {conv.fileName && <div className="bg-lightGray p-3 mt-6 w-2/4 cursor-pointer hover:brightness-75" id={conv.idActivitate} onClick={getImage}>Link</div>}
            
      </div>
    );
  });
}

export default TicketDetailsConversation;
