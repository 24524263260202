

function HelpCard() {
  return (
   <div style={{ height: "400px", width: "550px", zIndex: "1" }} className='shadow-lg border border-lightGray bg-white'>
        <div  className='px-8 py-4 font-bold mt-4'>
            <p className='text-2xl text-primary2'>Ai nevoie de ajutor?</p>
        </div>    
        <div style={{height: "80%"}} className='bg-imageCard bg-cover bg-center'></div>
    </div>
  )
}

export default HelpCard