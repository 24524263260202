import { useField, ErrorMessage } from "formik"

function TextFieldPlaceholder({ label, ...props }) {
    const [field, meta] = useField(props);
    // console.log(field.checked != undefined)
    let className = (field.checked === undefined) ? 'text-center text-black px-2 mb-4 border h-10' : '';
    return (
        <div className="mt-4 mx-auto flex flex-col justify-end items-center ">
            <input type="text" placeholder={label}
                className={`${className} ${meta.touched && meta.error && 'invalid-input'}`}
            {...field} {...props}
            />
            <ErrorMessage component="div" className="error block" name={ field.name }/>
        </div>
    )
}

export default TextFieldPlaceholder;