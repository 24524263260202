import React from 'react'
import { useAtom } from 'jotai';
import { subscriptionState } from '../../../state';
import { toast } from 'react-toastify';

function ContinueButtonSecondPage() {

    const [, setSelectedPage] = useAtom(subscriptionState.selectedPage);
    const [selectedCompaniesNumber] = useAtom(subscriptionState.selectedCompaniesNumber);
    const [addedCompanies] = useAtom(subscriptionState.addedCompanies);

  const handleClick = () => {
    if (parseInt(selectedCompaniesNumber) !== addedCompanies.length) {
      toast.warn('Va rugam sa adaugati toate companiile', {position: "top-center"});
      return;
    }
    setSelectedPage(3);
  }


  return (
   <button onClick={handleClick} className='bg-primary2 p-3 w-56 text-white text-center shadow-2xl'>Date facturare</button>
  )
}

export default ContinueButtonSecondPage