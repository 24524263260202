import CnpExceptions from "./CnpExceptions";

function CnpExceptionModal({ setIsOpen }) {
  return (
    <div className='shadow-lg h-full bg-white z-10'>
      <div className='flex justify-between items-center bg-primary2 p-3 shadow text-white'>
        <h3 className='text-2xl font-bold ml-8'>Setare exceptii</h3>
        <span
          onClick={() => setIsOpen(false)}
          className='material-icons cursor-pointer md-48'
        >
          cancel
        </span>
      </div>
      <div className='mt-16 px-11'>
        <div>
          <div>
            <CnpExceptions setIsOpen={setIsOpen} />
          </div>
        </div>
      </div>
    </div>
  );
}

export default CnpExceptionModal;
