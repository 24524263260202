import React            from "react";
import BenchLineChart   from "../charts/BenchLineChart";
import BenchBarChart    from "../charts/BenchBarChart";
import BenchStackedChart from "../charts/BenchStackedChart";

function GraphRowComponent({row,tableData,isDoubleColumn}) {
    const [showSecondaryLabel] = [true]
    let allRows = []
    if (row['tipGrafic'] === 3||row['tipGrafic'] === 4) {
        allRows = tableData?.filter(element => element["numeIndicator"] === row["numeIndicator"])
    }
    return (
        <>
            <td className='kpi-td w-full' colSpan={isDoubleColumn ?25 :12}>
                {row['tipGrafic'] === 1 ? <BenchBarChart row={row} isDoubleColumn={isDoubleColumn}/> : null}
                {row['tipGrafic'] === 2 ? <BenchLineChart row={row} isDoubleColumn={isDoubleColumn}/> : null}
                {row['tipGrafic'] === 3 ? BenchStackedChart(allRows, false,isDoubleColumn,showSecondaryLabel) : null}
                {row['tipGrafic'] === 4 ? BenchStackedChart(allRows, true,isDoubleColumn,showSecondaryLabel) : null}
            </td>
        </>
    );

}

export default GraphRowComponent;
