import React from 'react'

function CustomTooltip({ text, icon }) {
  return (
    <div className="tooltip">
      {icon}
    <div className="right">
        <div className="text-content">
            <p>
               {text}
            </p>
        </div>
        <i></i>
    </div>
    </div>
  )
}

export default CustomTooltip;