import { useState } from 'react';
import Modal from 'react-modal';

Modal.setAppElement("#root");

const customStyles = {
  overlay: {
    zIndex: 100,
    margin: "0",
    padding: "0",
  },
  content: {
      width: "90%",
      margin: "0 auto",
      padding: "0",
  },

};

function ModalComponent({ modalIsOpen, content }) {



  return (
    <div style={{ zIndex: "1000" }}>
      <Modal
        isOpen={modalIsOpen}
        style={customStyles}
        // onRequestClose={closeModal}
      >
        <div className='z-10 h-full'>{ content }</div>
      </Modal>
    </div>
  );  
}

export default ModalComponent