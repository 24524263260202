import { useState } from "react";
import RegisterFinish from "./register/RegisterFinish";
import RegisterForm from "./register/RegisterForm";

function RegisterFormPage() {
  const [clickedRegister, setClickedRegister] = useState(false);

  return (
    <div className='w-full'>
      {!clickedRegister ? (
        <div>
          <h3 className='text-center text-xl'>Ne bucura interesul tau!</h3>
          <p className='text-center'>
            Te rugam completeaza datele pentru finalizare
          </p>
          <RegisterForm setClickedRegister={setClickedRegister} />
        </div>
      ) : (
        <RegisterFinish />
      )}
    </div>
  );
}

export default RegisterFormPage;
