import { useAtom } from "jotai";
import { useEffect, useState, useMemo } from "react";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import { companyState, userState } from "../../state";
import image from '../../images/cards/homeCard4.png';






function ExceptionsCard({ handleClick, isOnHomePage, image }) {

  const [lastestTask, setLatestTask] = useAtom(companyState.lastestTask)
  const [unfinishedTasks, setUnfinishedTasks] = useAtom(companyState.unfinishedTasks);
  const [upcomingTask, setUpcomingTask] = useAtom(companyState.upcomingTask);


  const axiosPrivate = useAxiosPrivate();

  const [currentCompany] = useAtom(userState.currentCompany);





  const getCompletionState = async () => {
    try {
      setLatestTask("");
      setUnfinishedTasks("");
      setUpcomingTask("");

      const response = await axiosPrivate.get(`/api/completion-state?cui=${currentCompany.cif}`)
      console.log(response);
      setLatestTask(response.data.mesajCelMaiIntarziat);
      setUnfinishedTasks(response.data.mesajTaskuriNefinalizate);
      setUpcomingTask(response.data.mesajNextDeadline);
    } catch (e) {
      console.log(e);
    }
  }

  useEffect(() => {
    getCompletionState();
  }, [currentCompany])

  
 
  return (
    <div
      style={{ height: "400px", width: "550px", zIndex: "1", backgroundImage: `url(${image})` }}
      className='p-8 bg-white shadow-lg text-xl border border-lightGray font-bold'
    >
      <h3 className='text-primary2 mb-12 text-3xl'>
        {new Date().toLocaleDateString("ro-RO")}
      </h3>
      <div>
        {!isOnHomePage && <h3 className='text-primary2 font-bold text-2xl'>Incarca date</h3>}
        {!isOnHomePage && (
          <div className='flex items-center gap-4 mb-8'>
            <p className='text-gray'>Configureaza exceptii</p>
            <span
              onClick={handleClick}
              className='material-icons md-48 text-green cursor-pointer hover:brightness-125 hover:scale-150 transition-all'
            >
              folder
            </span>
          </div>
        )}
        <div style={{ marginTop: isOnHomePage && "70px" }} className='mb-6'>
          <p className='font-bold'>
            Task-uri nefinalizate: {unfinishedTasks}
          </p>
          <p className='font-bold text-primary2'>
            Cel mai intarziat:{" "}
            <span className='font-normal'>
              {lastestTask}
            </span>
          </p>
        </div>
        <div>
          <p className=''>Planificat in urmatoarea perioada:</p>
          <p>{upcomingTask}</p>
        </div>
      </div>
    </div>
  );
}

export default ExceptionsCard;
