import React from "react";
import SupportCard from "../components/tickets-page-components/SupportCard";
import AdminTicketDetails from "../components/tickets-page-components/tickets-conversation/AdminTicketDetails";
import Tickets from "../components/tickets-page-components/tickets-view-add/Tickets";
import HelpCard from "../components/upload-page-cards/HelpCard";
import { ticketsState, userState } from "../state";
import { useAtom } from "jotai";
import { useEffect } from "react";
import UserTicketDetails from "../components/tickets-page-components/tickets-conversation/UserTicketDetails";

function TicketsPage() {
  const [isTicketClicked] = useAtom(ticketsState.isTicketClicked);
  const [user] = useAtom(userState.user);
  // console.log(user);

  const [, setCompanyTickets] = useAtom(ticketsState.companyTickets);
  const [, setCurrentTicketConversation] = useAtom(
    ticketsState.currentTicketConversation
  );
  const [, setCurrentTicketData] = useAtom(ticketsState.currentTicketData);
  const [, setIsTicketClicked] = useAtom(ticketsState.isTicketClicked);

  const resetState = () => {
    setCompanyTickets([]);
    setCurrentTicketConversation([]);
    setCurrentTicketData({});
    setIsTicketClicked(false);
  };

  useEffect(() => {
    return () => {
      resetState();
    }
  }, [])

  return (
    <div style={{ maxWidth: "1700px" }} className='flex mx-24 mt-24'>
      <div style={{ zIndex: "3" }} className='w-full flex gap-8'>
        <div className='flex flex-col justify-between gap-14'>
          <SupportCard />
          <HelpCard />
        </div>
        <div
          style={{ minWidth: "1200px" }}
          className='bg-white p-6 pr-24 shadow-2xl relative'
        >
          <span className="material-icons absolute right-4 top-4 md-48 cursor-pointer" onClick={resetState}>backspace</span>
          {/* Cele 2 entitati main [(pagina de add si view all) si (pagina de conversatie)] */}
          {!isTicketClicked ? <Tickets resetState={resetState} /> : user.is_admin?  <AdminTicketDetails resetState={resetState}/> : <UserTicketDetails resetState={resetState} />}
        </div>
      </div>
    </div>
  );
}

export default TicketsPage;
