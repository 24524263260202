import { useState } from "react";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import { toast } from "react-toastify";
import { useAtom } from "jotai";
import { userState } from "../../state";

const defaultCnpFields = {
  cnp: "",
  description: "",
};

function CnpExceptionCreate({ getCnpExceptions, company }) {
  const [cnpFields, setCnpFields] = useState(defaultCnpFields);
    const { cnp, description } = cnpFields;
    const [user] = useAtom(userState.user);
    

  const axiosPrivate = useAxiosPrivate();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setCnpFields({ ...cnpFields, [name]: value });
  };

  const postCnp = async () => {
    try {
      await axiosPrivate.post("/api/cnp-exception", {
        companyId: company.id,
        cnp,
        description,
        userId: user.id
      });
      toast.success("Exceptia a fost adaugata cu succes", {
        position: "top-center",
      });
      setCnpFields(defaultCnpFields);
      getCnpExceptions();
    } catch (e) {
      console.log(e);
      toast.error("A aparut o eroare, incearca mai tarziu", {
        position: "top-center",
      });
    }
  };

  return (
    <div className='mt-4 flex gap-4'>
      <div className='mt-2'>
        <input
          className='border-2 w-60 h-12 p-3'
          type='text'
          placeholder='Cnp'
          onChange={handleChange}
          name='cnp'
          value={cnp}
          minLength='13'
          maxLength='13'
        />
      </div>
      <div className='mt-2'>
        <input
        style={{width: "500px"}}
          className='border-2 h-12 p-3'
          type='text'
          placeholder='Descriere/ Detalii'
          onChange={handleChange}
          name='description'
          value={description}
          required
        />
      </div>
      <div className='flex items-end'>
        <span onClick={postCnp} className='material-icons md-48 cursor-pointer'>
          add_box
        </span>
      </div>
    </div>
  );
}

export default CnpExceptionCreate;
