import { useField, ErrorMessage } from "formik"

function TextField({ label, onRegister, ...props }) {
    const [field, meta] = useField(props);
    // console.log(field.checked != undefined)
    let className = (field.checked === undefined) ? 'w-full text-black px-2 h-10' : '';
    return (
        <div className="mt-2">
            <label className="block" htmlFor="">{label}</label>
            <input type="text"
                className={`${className} ${meta.touched && meta.error && 'invalid-input'}`}
            {...field} {...props}
            />
            <ErrorMessage component="div" className={onRegister ? "error-yellow" : "error"} name={ field.name }/>
        </div>
    )
}

export default TextField