import React from "react";
import YourFunctionsChart from "../components/your-functions-components/your-functions-chart/YourFunctionsChart";
import YourFunctionsTable from "../components/your-functions-components/your-functions-table/YourFunctionsTable";
import YourFunctionsFetch from "../components/your-functions-components/YourFunctionsFetch";

function YourFunctionsPage() {
  return (
    <div style={{ maxWidth: "1700px" }} className='flex mx-24 mt-24'>
      <div style={{ zIndex: "3", minHeight: "880px" }} className=' bg-white w-full p-4'>
        <h1>
          Functiile tale <span className='material-icons md-48'>info</span>
        </h1>
        <div className='flex'>
          <div className="w-2/3">
            <YourFunctionsFetch />
            <YourFunctionsTable />
          </div>
          <div className="w-1/3">
            <YourFunctionsChart/>
          </div>
        </div>
      </div>
    </div>
  );
}

export default YourFunctionsPage;
