import { Form, Formik } from "formik";
import { useLocation, useNavigate } from "react-router-dom";
import Api from "../utilities/Api";
import ResetPasswordSchema from "../validations/ResetPasswordSchema";
import TextFieldPlaceholder from "../components/input-components/TextFieldPlaceholder";
import { toast } from "react-toastify";

const defaultInitialValues = {
  password: "",
  confirmPassword: "",
};

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

function ResetPasswordPage() {

  const query = useQuery();
  const navigate = useNavigate();

  const code = query.get("code");

  const sendData = async (values) => {
    const objectToSend = {
      password: values.password,
      code,
    };
    try {
      const response = await Api.post(
        "/api/reset-forgotten-password",
        objectToSend
      );
      toast.success("Parola a fost resetata cu succes, te poti loga", { position: "top-center" });
      navigate("/login");
      console.log(response);
    } catch (error) {
      toast.error("A aparut o eroare la resetarea parolei", { position: "top-center" });
      console.log(error);
    }
  };

  return (
    <div>
      <Formik
        initialValues={defaultInitialValues}
        validationSchema={ResetPasswordSchema}
        onSubmit={(values) => sendData(values)}
      >
        {(formik) => {
          return (
            <Form>
              <div>
                <TextFieldPlaceholder
                  label='Parola'
                  name='password'
                  type='password'
                />
              </div>
              <div>
                <TextFieldPlaceholder
                  label='Confirma Parola'
                  name='confirmPassword'
                  type='password'
                />
              </div>
              <div className='mt-4'>
                <button
                  className='py-2 px-4  w-24 bg-primary2 text-white hover:brightness-125 block mx-auto'
                  type='submit'
                >
                  Reset Password
                </button>
              </div>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
}

export default ResetPasswordPage;
