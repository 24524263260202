import { useEffect } from "react";
import { useState } from "react";
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
import { fileToByteString as fileToByteString } from "../../../utilities/FileHandler";
import { useAtom } from "jotai";
import { userState, ticketsState } from "../../../state";
import { toast } from "react-toastify";

const defaulValues = {
  category: 1,
  subject: "",
  description: "",
};

function TicketAdd({resetState}) {
  const axiosPrivate = useAxiosPrivate();

  const [user] = useAtom(userState.user);
  const [company] = useAtom(userState.currentCompany);
  const [, setCompanyTickets] = useAtom(ticketsState.companyTickets);

  const [formFields, setFormFields] = useState(defaulValues);
  const [categories, setCategories] = useState([]);
  const [fileByteString, setFileByteString] = useState("");
  const [fileName, setFileName] = useState("");

  useEffect(() => {
    getCategories();
  }, []);


  const imageToBytearray = async () => {
    // 5,242,880
    const fileName = document.getElementById("category-file-upload").files[0]
      .name;
    let file = document.getElementById("category-file-upload").files[0];
    if (file.size > 5242880) {
      toast.warn("File size larger than 5 mb", { position: "top-center" });
      file = "";
      return;
    }
    const response = await fileToByteString(file);
    console.log(response);
    setFileName(fileName);
    setFileByteString(response);
  };

  const getCategories = async () => {
    try {
      const response = await axiosPrivate.get("/tickets-categories");
      console.log(response.data);
      setCategories(response.data);
    } catch (e) {
      console.log(e);
    }
  };

  const { category, subject, description } = formFields;

  const handleChange = (e) => {
    const { name, value } = e.target;
    console.log(formFields);
    setFormFields({ ...formFields, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const ticketBody = {
      idUser: user.id,
      idCompanie: company.id,
      idCategorie: category,
      subiect: subject,
      // descriere: description,
      // atasament: fileByteString,
      // numeFisier: fileName
    };
    const ticketActivityBody = {
      idUser: user.id,
      continut: description,
      atasament: fileByteString,
      numeFisier: fileName,
    };
    try {
      const response = await axiosPrivate.post("/add-ticket", { ticketBody, ticketActivityBody });
      console.log(response.data);
      const response2 = await axiosPrivate.get(`/company-tickets?cui=${company.cif}`);
      // console.log(response.data);
      setCompanyTickets(response2.data)
    } catch (e) {
      console.log(e);
    }

    // console.log(fileByteArray);

    try {
      axiosPrivate.post();
    } catch (e) {
      console.log(e);
    }
    // saveAsXlsxFile(fileByteArray, "file.png")
  };

  return (
    <div className='mt-8'>
      <form onSubmit={handleSubmit}>
        <div className='flex items-end gap-8'>
          <div className='w-full'>
            <div className='flex gap-8 justify-between'>
              <div className='w-full'>
                <p className='font-bold text-2xl'>Categorie</p>
                {/* <input
                  name='category'
                  value={category}
                  onChange={handleChange}
                  type='text'
                  className='w-full block border-primary2 border-2 h-10 mt-2 p-2'
                /> */}
                <select
                  onChange={handleChange}
                  value={category}
                  type='text'
                  name='category'
                  id='category'
                  className='w-full block border-primary2 border-2 h-10 mt-2 p-2'
                >
                  {categories.map((category) => {
                    return (
                      <option
                        key={category.idCategorie}
                        value={category.idCategorie}
                      >
                        {category.afisaj}
                      </option>
                    );
                  })}
                </select>
              </div>
              <div className='w-full'>
                <p className='font-bold text-2xl'>Subiect</p>
                <input
                  name='subject'
                  value={subject}
                  onChange={handleChange}
                  type='text'
                  className='w-full block border-primary2 border-2 h-10 mt-2 p-2'
                />
              </div>
            </div>
            <div className='flex gap-8 mt-2'>
              <div className='w-full'>
                <p className='mt-2 bg-primary2 p-2 text-lightGray'>
                  Descriere/ Detalii
                </p>
              </div>
              <div className='w-full flex items-center'>
                <span
                  className='material-icons md-48 mt-2 cursor-pointer'
                  onClick={() =>
                    document.getElementById("category-file-upload").click()
                  }
                >
                  attach_file
                </span>
                <input
                  type='file'
                  name=''
                  className='hidden'
                  accept="image/png, image/jpeg, image/jpg,"
                  id='category-file-upload'
                  onChange={imageToBytearray}
                />
              </div>
            </div>
            <div>
              <textarea
                name='description'
                value={description}
                onChange={handleChange}
                id=''
                cols='100'
                rows='10'
                className='w-full border-2 border-primary2 p-2'
                placeholder='Descriere/ Detalii'
              ></textarea>
            </div>
          </div>

          <div>
            <button className='material-icons md-48 cursor-pointer'>
              add_box
            </button>
          </div>
        </div>
      </form>
    </div>
  );
}

export default TicketAdd;
