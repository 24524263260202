import SelectedSubscriptionInfo from "../common/SelectedSubscriptionInfo";
import ShowMainCompany from "./ShowMainCompany";
import InvoiceForm from "./InvoiceForm";
import BackButton from "../common/BackButton";

function SubscriptionsThirdPageForm() {
  return (
    <div
      style={{ minWidth: "1200px" }}
      className='bg-white p-6 pr-24 shadow-2xl relative'
    >
      <SelectedSubscriptionInfo />
      <div className='flex mt-12 gap-8'>
        <ShowMainCompany />
        <div style={{ width: "2px", backgroundColor: "gray" }}></div>
        <InvoiceForm />
      </div>
      <BackButton gotoPage={2} />
    </div>
  );
}

export default SubscriptionsThirdPageForm;
