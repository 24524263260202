import { atomWithStorage } from "jotai/utils";
import { atom, useAtom } from "jotai";

const token = atomWithStorage("token", "");

//user state
const user = atom("");
const currentCompany = atomWithStorage("currentCompany", "");
const extendedAccess = atom(false);

//upload page state
const uploadingDates = atom([{ fileType: "test", date: "test" }]);

//kpi state
const tableData = atom([]);
const hasTableUpdated = atom(false);
const withNull = atom(false);
const showGraph = atom(false);
const filterIndicatori = atom("");
const selectedYearOption = atom(new Date().getFullYear().toString());
const selectedCorLevelOption = atom("0");
const corNameOptions = atom([{ label: "0 | Toata firma", value: "0" }]);
const selectedCorNameOption = atom({ label: "0 | Toata firma", value: "0" });
const selectedStandardOption = atom({ label: "COR", value: "1" });

const selectedAggregationLevelOption = atom({label: "0 - Toata populatia", value: '0'});
const selectedAggregationGroup = atom({ label: "1 | Toata firma", value: "1" });

//bench state
const benchTableData = atom([]);
const benchFilterIndicatori = atom("");
const benchHasTableUpdated = atom(false);
const benchWithNull = atom(false);
const benchShowGraph = atom(false);
const benchSelectedYearOption = atom(new Date().getFullYear().toString());
const benchSelectedCorLevelOption = atom("0");
const benchSelectedCorNameOption = atom({
  label: "0 | Toata firma",
  value: "0",
});
const benchCorNameOptions = atom([{ label: "0 | Toata firma", value: "0" }]);
const benchSelectedAggregationLevelOption = atom("0");
const benchSelectedAggregationGroup = atom({
  label: "1 | Toata firma",
  value: "1",
});
const benchSelectedStandardOption = atom({ label: "COR", value: "1" });

//extended bench state
const extendedBenchTableData = atom([]);
const extendedBenchFilterIndicatori = atom("");
const extendedBenchHasTableUpdated = atom(false);
const extendedBenchWithNull = atom(false);
const extendedBenchShowGraph = atom(false);
const extendedBenchSelectedYearOption = atom(
  new Date().getFullYear().toString()
);
const extendedBenchSelectedCorLevelOption = atom("0");
const extendedBenchSelectedCorNameOption = atom({
  label:
    "1 | MEMBRI AI CORPULUI LEGISLATIV, AI EXECUTIVULUI, INALTI CONDUCATORI AI ADMINISTRATIEI PUBLICE, CONDUCATORI SI FUNCTIONARI SUPERIORI",
  value: "1",
});
const extendedBenchCorNameOptions = atom([
  { label: "0 | Toata firma", value: "0" },
]);
const extendedBenchSelectedAggregationLevelOption = atom("0");
const extendedBenchSelectedAggregationGroup = atom({
  label: "1 | Toata firma",
  value: "1",
});
const extendedBenchSelectedStandardOption = atom({ label: "COR", value: "1" });

// subscription state
const selectedSubscription = atom("Abonamet promotional 1 an + 1 an gratuit");
const selectedCompaniesNumber = atom(1);
const employeesNumber = atom(1000);
const addOn = atom(false);
const vipAddon = atom(false);
const listPrice = atom(0);
const discountName = atom("");
const discount = atom(0);
const finalPrice = atom(0);
const selectedPage = atom(1);
const addedCompanies = atom([]);
const mainCompany = atom("");
const currentSelectedRadio = atom(0);

const lastestTask = atom("");
const unfinishedTasks = atom("");
const upcomingTask = atom("");

const companyTickets = atom([]);
const isTicketClicked = atom(false);
const currentTicketData = atom({});
const currentTicketConversation = atom([]);

const yourFunctions = atom([]);
const yourFunctionsWithNull = atom(false);
const yourFunctionsGraphData = atom([]);
const columnNames = atom([
  { label: "Numar Angajati in luna", value: "1" },
  { label: "Angajari in an", value: "2" },
  { label: "Intreruperi in an", value: "3" },
  { label: "Fond salarii brute in an", value: "4" },
  { label: "Fond venituri brute", value: "5" },
]);
  
export const state = {
  token,
};

export const userState = { user, currentCompany, extendedAccess };

export const companyState = { lastestTask, unfinishedTasks, upcomingTask };

export const kpiState = {
  tableData,
  hasTableUpdated,
  withNull,
  showGraph,
  filterIndicatori,
  selectedYearOption,
  selectedCorLevelOption,
  corNameOptions,
  selectedCorNameOption,
  selectedStandardOption,
};

export const benchState = {
  benchTableData,
  benchHasTableUpdated,
  benchWithNull,
  benchShowGraph,
  benchFilterIndicatori,
  benchSelectedYearOption,
  benchSelectedCorLevelOption,
  benchSelectedCorNameOption,
  benchCorNameOptions,
  benchSelectedAggregationLevelOption,
  benchSelectedAggregationGroup,
  benchSelectedStandardOption,
};

export const extendedBenchState = {
  extendedBenchTableData,
  extendedBenchHasTableUpdated,
  extendedBenchWithNull,
  extendedBenchShowGraph,
  extendedBenchFilterIndicatori,
  extendedBenchSelectedYearOption,
  extendedBenchSelectedCorLevelOption,
  extendedBenchSelectedCorNameOption,
  extendedBenchCorNameOptions,
  extendedBenchSelectedAggregationLevelOption,
  extendedBenchSelectedAggregationGroup,
  extendedBenchSelectedStandardOption,
};

export const subscriptionState = {
  selectedSubscription,
  selectedCompaniesNumber,
  finalPrice,
  listPrice,
  discountName,
  discount,
  selectedPage,
  addedCompanies,
  mainCompany,
  employeesNumber,
  addOn,
  vipAddon,
  currentSelectedRadio,
};

export default state;

export const pageState = {
  tableData,
  hasTableUpdated,
  withNull,
  showGraph,
  filterIndicatori,
  selectedYearOption,
  selectedCorLevelOption,
  corNameOptions,
  selectedCorNameOption,
  selectedStandardOption,
  selectedAggregationLevelOption,
  selectedAggregationGroup,
};

export const ticketsState = {
  companyTickets,
  isTicketClicked,
  currentTicketData,
  currentTicketConversation,
};


export const yourFunctionsState = {
  yourFunctions,
  yourFunctionsWithNull,
  yourFunctionsGraphData,
  columnNames
}

export const uploadPageState = {
  uploadingDates,
}