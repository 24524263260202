import React, {useState} from "react";
import {Chart as ChartJS, ArcElement, Tooltip, Legend} from "chart.js";
import {Bar} from "react-chartjs-2";
import {Chart, registerables} from "chart.js";
import ModalComponent from "../ModalComponent";
import ChartjsPluginStacked100 from "chartjs-plugin-stacked100";

Chart.register(...registerables);
Chart.register(ChartjsPluginStacked100);


function BenchStackedChart(allRows,stacked,isDoubleColumn,showSecondaryLabel) {
    ChartJS.register(ArcElement, Tooltip, Legend);

    const [modalIsOpen, setIsOpen] = useState(false);
    const plugins = {stacked100:{enable:stacked},legend:{labels:{filter:function(item,chart){return !item.text.includes('Piata')||showSecondaryLabel}}}}
    const months = ["jan", "feb", "mar", "apr", "may", "jun", "jul", "aug", "sep", "oct", "nov", "dec",];
    let data = allRows?.map((row) => {
        return {
            label: row.numeIndicator + (row.afisajDiviziune ? "  " + row.afisajDiviziune : ""),
            data: months.map((month) => (row[month] ? row[month] : 0)), // backgroundColor: [row.culoareSectiune],
            backgroundColor: [row.culoareDiviziune], // borderColor: [row.culoareGrafic],
            stack:'Own'
        };
    })
    let data2 = allRows?.map((row) => {
        return {
            label: "Piata - "+row.numeIndicator + (row.afisajDiviziune ? "  " + row.afisajDiviziune : ""),
            data: months.map((month,index) => (row[`bench_${index+1}`] ? row[`bench_${index+1}`] : 0)), // backgroundColor: [row.culoareSectiune],
            backgroundColor: [row.culoareDiviziune], // 
            borderColor: [row.culoareGrafic],
            stack:'Other'
        };
    })
    // console.log('allrows:',allRows)
    const data3 = isDoubleColumn?data.concat(data2):data
    // console.log('chaart stack data:',data3)
    let nutData = {
        labels: months, datasets: data3,
    };
    // console.log('si datele prelucrate pt stack', nutData)

    return (<div className='relative'>
            <Bar
                data={nutData}
                options={{
                    categoryPercentage: 0.75,
                    barPercentage: 0.85,  
                    maintainAspectRatio: false,
                    scales: {
                        x: {
                            stacked: true,
                        }, y: {
                            stacked: true,
                        },
                    }, 

                    plugins:plugins
                }}
                className='pl-8 pr-10'
            />
            <div
                onClick={() => setIsOpen(true)}
                style={{top: "20%", right: "6px", fontSize: "30px"}}
                className='absolute material-icons cursor-pointer hover:scale-150 transition-all'
            >
                zoom_in_map
            </div>
            <ModalComponent
                modalIsOpen={modalIsOpen}
                content={<>
                    <div className='flex justify-between w-full text-3xl'>
                        <p></p>
                        <span
                            onClick={() => setIsOpen(false)}
                            className='material-icons cursor-pointer md-48 mt-4 mr-4'
                        >
                cancel
              </span>
                    </div>
                    <Bar
                        data={nutData}
                        options={{
                            scales: {
                                x: {
                                    stacked: true,
                                }, y: {
                                    stacked: true,
                                },
                            },
                            plugins:plugins
                        }}
                        className='pl-8 pr-10'
                    />
                </>}
            />
        </div>);
}

export default BenchStackedChart;
