import { useAtom } from "jotai";
import React from "react";
import { subscriptionState } from "../../state";
import SubscriptionsFirstPageForm from "./firstPage/SubscriptionsFirstPageForm";
import SubscriptionsSecondPageForm from "./secondPage/SubscriptionsSecondPageForm";
import SubscriptionsThirdPageForm from "./thirdPage/SubscriptionsThirdPageForm";

function SubscriptionSelectedPage() {
  const [selectedPage] = useAtom(subscriptionState.selectedPage);
  

  const getFormPage = () => {
    if (selectedPage === 1) {
      return <SubscriptionsFirstPageForm />;
    } else if (selectedPage === 2) {
      return <SubscriptionsSecondPageForm />;
    } else if (selectedPage === 3) {
      return <SubscriptionsThirdPageForm />;
    }
  };

  return getFormPage();
}

export default SubscriptionSelectedPage;
