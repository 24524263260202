import { useNavigate } from "react-router-dom";
import RegisterSchema from "../../../validations/RegisterSchema";
import { Formik, Form, ErrorMessage } from "formik";
import TextField from "../../input-components/TextField";
import CheckboxField from "../../input-components/CheckboxField";
import Api from "../../../utilities/Api";
import { toast } from "react-toastify";
import { useState } from "react";
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
import InputWarning from "../../subscriptions-page-components/common/InputWarning";
import { useEffect } from "react";

function RegisterForm({setClickedRegister}) {
  const axiosPrivate = useAxiosPrivate();

  let navigate = useNavigate();
  const [isRegisterDisabled, setIsRegisterDisabled] = useState(false);
  const [usedApplicationOptions, setUsedApplicationOptions] = useState([]);
  const [usedApplicationState, setUsedApplicationState] = useState(0);
  const [pressedSubmit, setPressedSubmit] = useState(false);

  const getUsedApplications = async () => {
    const response = await axiosPrivate.get("/api/used-applications");
    console.log(response);
    setUsedApplicationOptions(response.data);
  };

  useEffect(() => {
    // setUsedApplicationOptions
    getUsedApplications();
  }, []);

  return (
    <>
      <Formik
        initialValues={{
          firstName: "",
          lastName: "",
          email: "",
          password: "",
          confirmPassword: "",
          usedApplication: "",
          twoFa: false,
          termsConditions: false,
        }}
        validationSchema={RegisterSchema}
        onSubmit={(values) => {
          if (!usedApplicationState) {
            setPressedSubmit(true);
            return;
          }
          setIsRegisterDisabled(true);
          console.log(values);
          Api.post("register", {
            firstName: values.firstName,
            lastName: values.lastName,
            email: values.email,
            password: values.password,
            confirmPassword: values.confirmPassword,
            usedApplication: usedApplicationState,
            twoFa: values.twoFa,
            termsConditions: values.termsConditions,
          })
            .then((data) => {
              console.log(data);
              toast.success(
                "Multumim pentru intregistrare. Codul de activare a fost transmis pe adresa de email. Verifica inbox si Spam",
                { position: "top-center" }
              );
              // setToken(data.data.jwtToken);
              // setIsRegisterDisabled(false);
              // navigate("/login");
              setClickedRegister(true);
            })
            .catch((error) => {
              // setIsRegisterDisabled(false);
              console.log(error.response.status);
              if (error.response.status === 500) {
                toast.error("Eroare procesare query sql", {
                  position: "top-center",
                });
              } else {
                toast.error("Emailul exista deja", { position: "top-center" });
              }
            })
            .finally(() => {
              setIsRegisterDisabled(false);
            });
        }}
      >
        {(formik) => {
          return (
            <Form className='mt-4'>
              <div className='flex gap-4 justify-between'>
                <div>
                  <TextField
                    label='Prenume'
                    name='firstName'
                    onRegister={true}
                    type='text'
                  />
                </div>
                <div>
                  <TextField
                    label='Nume'
                    name='lastName'
                    onRegister={true}
                    type='text'
                  />
                </div>
              </div>
              <div>
                <div>
                  <TextField
                    label='Adresa de email'
                    name='email'
                    onRegister={true}
                    type='email'
                  />
                </div>
              </div>
              <div>
                <div>
                  <TextField
                    label='Parola'
                    name='password'
                    onRegister={true}
                    type='password'
                  />
                </div>
                <div>
                  <TextField
                    label='Reintrodu Parola'
                    name='confirmPassword'
                    onRegister={true}
                    type='password'
                  />
                </div>
              </div>
              <div className='mt-4 relative'>
                {/* <TextField
                  label='Ce aplicatie folosesti pentru generare revisal?'
                  name='usedApplication'
                  type='text'
                /> */}
                <label className='block' htmlFor=''>
                  Ce aplicatie folosesti pentru generare revisal
                </label>
                <select
                  className='border border-gray py-1 px-2 text-black'
                  type='text'
                  name='usedApplication'
                  id='usedApplication'
                  onChange={(e) => setUsedApplicationState(e.target.value)}
                  value={usedApplicationState}
                >
                  <option value={0} disabled>
                    Selecteaza ce revisal folosesti
                  </option>
                  {usedApplicationOptions.map((application) => {
                    return (
                      <option key={application.idTip} value={application.idTip}>
                        {application.tipRevisal} - {application.descriereTip}
                      </option>
                    );
                  })}
                </select>
                {pressedSubmit && !usedApplicationState && (
                  <InputWarning text='Aplicatia folosita este necesara' />
                )}
              </div>
              {/* <div className='mt-2'>
                <CheckboxField name='twoFa' />
                <p className='inline'>Activeaza 2FA</p>
              </div> */}
              <div className='mt-5'>
                <CheckboxField name='termsConditions' />
                <p className='inline'>
                  Sunt de acord cu
                  <a href='https://www.benefiq.ro/politica_de_confidentialitate/'>
                    <i className='text-yellow inline'>
                      {" "}
                      termenii si conditiile
                    </i>
                  </a>
                </p>
              </div>
              <div className='mt-2'>
                <button
                  style={{
                    backgroundColor: "#ffc001",
                    cursor: isRegisterDisabled ? "not-allowed" : "pointer",
                  }}
                  disabled={isRegisterDisabled}
                  className='text-white hover:brightness-125 w-full px-4 py-1 my-4'
                  type='submit'
                >
                  Finalizeaza
                </button>
              </div>
            </Form>
          );
        }}
      </Formik>
    </>
  );
}

export default RegisterForm;
