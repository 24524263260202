import { useAtom } from 'jotai'
import React from 'react'
import { subscriptionState } from '../../../state'

function ContinueButtonFirstPage() {

  

  return (
    <button type='submit' className='bg-primary2 p-3 w-56 text-white text-center shadow-2xl'>Vreau abonament</button>
  )
}

export default ContinueButtonFirstPage;