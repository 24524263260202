import React, { useEffect, useState } from "react";
import { useAtom } from "jotai";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import Select from "react-dropdown-select";
import Dropdown from "react-dropdown";
import CalculateKpi from "./CalculateKpi";
import { initializeTable } from "../../utilities/TableUtilities";
import { pageState, userState } from "../../state";

function GeneralBenchFetchButton({ pageType }) {
  const axiosPrivate = useAxiosPrivate();
  const [company] = useAtom(userState.currentCompany);
  const [user] = useAtom(userState.user);
  const [corNameOptions, setCorNameOptions] = useAtom(pageState.corNameOptions);
  const [selectedCorLevelOption, setSelectedCorLevelOption] = useAtom(
    pageState.selectedCorLevelOption
  );
  const [selectedCorNameOption, setSelectedCorNameOption] = useAtom(
    pageState.selectedCorNameOption
  );
  const [selectedAggregationLevelOption, setSelectedAggregationLevelOption] =
    useAtom(pageState.selectedAggregationLevelOption);
  const [selectedAggregationGroup, setSelectedAggregationGroup] = useAtom(
    pageState.selectedAggregationGroup
  );
  const [selectedYearOption, setSelectedYearOption] = useAtom(
    pageState.selectedYearOption
  );
  const [selectedStandardOption, setSelectedStandardOption] = useAtom(
    pageState.selectedStandardOption
  );
  const [, setFilterIndicatori] = useAtom(pageState.filterIndicatori);
  const [, setTableData] = useAtom(pageState.tableData);
  const [, setHasTableUpdated] = useAtom(pageState.hasTableUpdated);

  const [yearOptions, setYearOptions] = useState([]);
  const [corLevelOptions, setCorLevelOptions] = useState([
    "0",
    "1",
    "2",
    "3",
    "4",
    "6",
  ]);
  const [aggregationLevelOptions] = useState([
    { label: "0 - Toata populatia", value: "0" },
    { label: "1 - Sectiune CAEN", value: "1" },
    { label: "2 - Județ", value: "2" },
    { label: "3 - Asociatii", value: "3" },
  ]);
  const [aggregationGroup, setAggregationGroup] = useState([
    { label: "1 | Toata firma", value: "1" },
  ]);
  const [standardOptions] = useState([
    { label: "COR", value: "1" },
    { label: "ISCO", value: "2" },
  ]);

  const getYearsOptions = async () => {
    try {
      const response = await axiosPrivate.get(
        `/api/get-company-years?cui=${company.cif}`
      );
      console.log(response.data.map((obj) => obj.year));
      setYearOptions(response.data.map((obj) => obj.year));
    } catch (e) {
      console.log(e);
    }
  };

  const handleStandardChange = (e) => {
    console.log(e);
    setSelectedStandardOption(e);
    // handleCorLevelChange();
    setCorLevelOptions(
      e.value === "1"
        ? ["0", "1", "2", "3", "4", "6"]
        : ["0", "1", "2", "3", "4"]
    );
  };

  useEffect(() => {
    handleCorLevelChange();
  }, [selectedStandardOption]);

  const handleCorLevelChange = async (e) => {
    if (!e) {
      e = {};
      e.value = "0";
    }
    //todo change setCorNameOptions;
    try {
      const response = await axiosPrivate.get(
        `/api/view-cor?cui=${company.cif}&year=${selectedYearOption}&corLevel=${e.value}&standard=${selectedStandardOption.value}`
      );
      // console.log(response)
      const corNameOptions = parseCorNameOptions(response);
      // console.log(conrNameOptions);
      setSelectedCorLevelOption(e.value);
      setCorNameOptions(corNameOptions);
      setSelectedCorNameOption({
        label: "Selecteaza...",
        value: corNameOptions[0].value,
      });
    } catch (e) {
      console.log("error fetching cor change :", e);
    }
    console.log(e);
    // setCorLevelValue(e.value);
    setSelectedCorLevelOption(e.value); //TODO: @Robert : de ce era comentata ?? la kpi normal era ok; aici era comentata.. ? era o eficientizare ? vad ca se face in try ?
    // setSelectedCorLevelOption(e.value);

    // console.log(e.value);
  };

  const handleBenchAggregationLevelChange = async (e) => {
    console.log(e);
    console.log(selectedStandardOption);
    const url = `/api/view-aggregation-group?aggregationLevel=${e.value}&year=${selectedYearOption}&standard=${selectedStandardOption.value}&cui=${company.cif}`;
    console.log("url pt bench agr level pe care primesc eroare ! :", url);
    try {
      const response = await axiosPrivate.get(url);
      const corNameOptions = parseCorNameOptions(response);
      console.log(response);
      setSelectedAggregationLevelOption(e.value);
      setAggregationGroup(corNameOptions);
      setSelectedAggregationGroup({
        label: "Selecteaza...",
        value: corNameOptions[0].value,
      });
    } catch (e) {
      console.log(e);
    }
  };

  const parseCorNameOptions = (response) => {
    return response.data.map((cor) => {
      return {
        value: cor.cor.split("|")[0].trim(),
        label: cor.cor.trim(),
      };
    });
  };

  const getAllKpi = async () => {
    // console.log('pagetype:', pageType);
    const url = `/api/${
      pageType === "kpiPage"
        ? "kpi"
        : pageType === "benchPage"
        ? "bench"
        : "extended-bench"
    }?cui=${
      company.cif
    }&year=${selectedYearOption}&corLevel=${selectedCorLevelOption}&cor=${
      selectedCorNameOption.value
        ? selectedCorNameOption.value
        : corNameOptions[0].value
    }&companyId=${
      company.id
    }&aggregationLevel=${selectedAggregationLevelOption.value}&aggregationGroup=${
      selectedAggregationGroup.value
        ? selectedAggregationGroup.value
        : aggregationGroup[0].value
    }&userId=${user.id}&standard=${selectedStandardOption.value}`;
    // console.log(`incerc url-ul:`,url)
    try {
      const response = await axiosPrivate.get(url);
      (selectedCorNameOption.label === "Selecteaza..." ||
        !selectedCorNameOption.label) &&
        setSelectedCorNameOption(corNameOptions[0]);
      // console.log('tot raspunsul revenit este : (ne intereseaza data[0]) ',response)
      (selectedAggregationGroup.label === "Selecteaza..." ||
        !selectedAggregationGroup.label) &&
        setSelectedAggregationGroup(aggregationGroup[0]);

      // console.log(response.data);
      // setTableData(response.data);
      // console.log('tip pagina', pageType);
      setTableData(initializeTable(response.data, pageType));

      setHasTableUpdated((prevValue) => !prevValue);
    } catch (e) {
      console.log(e);
    }
  };

  const getAllResetKpi = async () => {
    let url = `/api/${
      pageType === "kpiPage"
        ? "kpi"
        : pageType === "benchPage"
        ? "bench"
        : "extended-bench"
    }?cui=${company.cif}&year=${new Date()
      .getFullYear()
      .toString()}&corLevel=${0}&cor=${0}&companyId=${
      company.id
    }&aggregationLevel=${0}&aggregationGroup=${1}&userId=${
      user.id
    }&standard=${1}`;
    console.log("url unde incearca : ", url); //TODO: atentie, nu am rezolvat bug daca intra direct pe pagina !
    try {
      console.log("cor name value");
      console.log(selectedCorNameOption);
      const response = await axiosPrivate.get(url);
      // console.log(response);
      // console.log('tip pagina', pageType);
      setTableData(initializeTable(response.data, pageType));
      setHasTableUpdated((prevValue) => !prevValue);
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    // am nevoie sa aduc anii de upload ai acelei companii si sa resetez anul ca fiind anul curent
    getYearsOptions();
    setSelectedYearOption(new Date().getFullYear().toString());

    // am nevoie sa schimb doar valoare curenta, optiunile sunt mereu aceleasi
    setSelectedCorLevelOption("0");

    // am nevoie sa schimb si valoare curenta, cat si optiunile
    setSelectedCorNameOption({ label: "0 | Toata firma", value: "0" });
    setCorNameOptions([{ label: "0 | Toata firma", value: "0" }]);

    //resetez nivel agregare
    setSelectedAggregationLevelOption({
      label: "0 - Toata populatia",
      value: "0",
    });

    //resetez grup agregare
    setSelectedAggregationGroup({ label: "1 | Toata populatia", value: "1" });

    // la final, fac fetch cu noile date, hardcodat, pentru ca seterele sunt async si se face fetch cu datele vechi

    getAllResetKpi();
  }, [company, user, pageType]);

  return (
    <div className='flex items-center gap-8 text-sm'>
      <div>
        <p className='ml-2'>Standard</p>
        <Dropdown
          menuClassName='active-scroll'
          className='w-24'
          controlClassName='cursor-pointer'
          options={standardOptions}
          value={selectedStandardOption}
          onChange={handleStandardChange}
        />
      </div>
      <div>
        <p className='ml-2'>Nivel</p>
        <Dropdown
          menuClassName='active-scroll'
          className='w-24'
          controlClassName='cursor-pointer'
          options={corLevelOptions}
          value={selectedCorLevelOption}
          onChange={handleCorLevelChange}
        />
      </div>
      <div>
        <p className='ml-2'>Denumire</p>
        <Select
          placeholder='Selecteaza...'
          options={corNameOptions}
          style={{ width: "288px", height: "39px" }}
          values={[selectedCorNameOption]}
          clearOnSelect={true}
          onDropdownOpen={() => setSelectedCorNameOption("")}
          // onDropdownClose={() => setSelectedCorNameOption(selectedCorNameOption !== "" ? selectedCorLevelOption.value : { label: "0 | Toata firma", value: "0" })}
          onChange={(e) => setSelectedCorNameOption(e[0])}
        />
      </div>
      <div>
        <p className='ml-2'>An</p>
        <Dropdown
          menuClassName='active-scroll'
          className='w-24'
          controlClassName='cursor-pointer'
          options={yearOptions}
          value={selectedYearOption}
          onChange={(e) => setSelectedYearOption(e.value)}
        />
      </div>
      {pageType !== "kpiPage" && (
        <>
          {/* <div>
            <p className="text-3xl font-bold">VS</p>
          </div> */}
          <div style={{ minWidth: "120px" }}>
            <p>Nivel agregare</p>
            <Dropdown
              menuClassName='active-scroll'
              // className='w-48'
              controlClassName='cursor-pointer'
              options={aggregationLevelOptions}
              value={selectedAggregationLevelOption}
              onChange={handleBenchAggregationLevelChange}
            />
          </div>
          <div>
            <p>Grup agregare</p>
            <Select
              placeholder='Selecteaza...'
              options={aggregationGroup}
              style={{ width: "288px", height: "39px" }}
              values={[selectedAggregationGroup]}
              clearOnSelect={true}
              onDropdownOpen={() => setSelectedAggregationGroup("")}
              onChange={(e) => setSelectedAggregationGroup(e[0])}
            />
          </div>
        </>
      )}
      <div>
        <span
          style={{ fontSize: "58px" }}
          onClick={getAllKpi}
          className='material-icons mt-6 cursor-pointer text-green'
        >
          play_circle
        </span>
      </div>
      <div>
        <label htmlFor='filter' className='block ml-2 text-md'>
          Filtreaza indicatori
        </label>
        <input
          name='filter'
          id='filter'
          type='search'
          placeholder='Filtreaza indicatori'
          className='rounded-none border block h-10 px-2 focus:outline-0'
          onChange={(event) =>
            setFilterIndicatori(
              event.target.value.length > 2 ? event.target.value : ""
            )
          }
        />
      </div>
      {pageType === "kpiPage" && (
        <CalculateKpi selectedYear={selectedYearOption} />
      )}
    </div>
  );
}

export default GeneralBenchFetchButton;
