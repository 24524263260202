import { useAtom } from "jotai";
import { useState, useEffect } from "react";
import { userState } from "../../state";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import { Link } from "react-router-dom";

function YourSubscriptionsCard({ image }) {
  const [currentCompany] = useAtom(userState.currentCompany);
  const [expirationDate, setExpirationDate] = useState("");
  const [companies, setCompanies] = useState([]);
  const axiosPrivate = useAxiosPrivate();

  const getCompanyStartEndDates = async () => {
    try {
      const response = await Promise.all([
        axiosPrivate.get(
          `/api/get-company-start-end?companyId=${currentCompany?.id}`
        ),
        axiosPrivate.get("/api/get-user-companies"),
      ]);

      const date = new Date(response[0].data[1]);
      setExpirationDate(date);
      setCompanies(response[1].data);
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    getCompanyStartEndDates();
  }, [currentCompany]);

  return (
    <Link
      to='/subscriptions'
      style={{
        height: "400px",
        width: "550px",
        zIndex: "1",
        backgroundImage: `url(${image})`,
      }}
      className='pl-8 pr-16 py-8 shadow-lg w-full h-max bg-white text-xl font-bold'
    >
      <h2 className='text-primary2 text-3xl font-black mt-24 font-montserrat'>
        ABONAMENTELE TALE
      </h2>
      <div className='font-bold mt-2'>
        <h3>
          {currentCompany?.denumireCompanie}{" "}
          {companies?.length - 1 > 0 && `(+${companies?.length - 1} extra)`}
        </h3>
        <h3 className='text-accent text-base'>
          Data expirare:{" "}
          {expirationDate.toLocaleString("default", {
            month: "short",
            year: "numeric",
          })}
        </h3>
      </div>
      <p className='mt-24'>
        Vezi detalii abonamente si extra features...
      </p>
    </Link>
  );
}

export default YourSubscriptionsCard;
