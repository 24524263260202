import React, { useEffect, useState, useRef } from "react";
import { Doughnut, getElementAtEvent } from "react-chartjs-2";
import { useAtom } from "jotai";
import { yourFunctionsState } from "../../../state";
import Dropdown from "react-dropdown";

function YourFunctionsChart() {
  const chartRef = useRef();

  const [options] = useAtom(yourFunctionsState.columnNames);
  const [currentOptionIndex, setCurrentOptionIndex] = useState(0);

  // const [yourFunctionsGraphData] = useAtom(yourFunctionsState.yourFunctionsGraphData)
  const [yourFunctions] = useAtom(yourFunctionsState.yourFunctions);
  const [selectedOption, setSelectedOption] = useState({
    label: "Numar Angajati in luna",
    value: "1",
  });
  const [currentData, setCurrentData] = useState({
    labels: [],
    datasets: [{ label: "", data: [], backgroundColor: [] }],
  });
  const [currentParent, setCurrentParent] = useState("");
  const [parents, setParents] = useState([{ cor: "", denumireCor: "Start" }]);

  const handleDoughnutElementClick = (event) => {
    const clickedDenumireCor = printElementAtEvent(
      getElementAtEvent(chartRef.current, event)
    );
    const foundCor = getCorByDenumire(clickedDenumireCor);
    if (foundCor.length === 6) return
    setCurrentParent(foundCor);

    setParents((prevState) => [
      ...prevState,
      { cor: foundCor, denumireCor: clickedDenumireCor },
    ]);
  };

  const handleBreadCrumbClick = (parent) => {
    if (parent.cor === currentParent) return null
    setCurrentParent(parent.cor);

    const filteredParents = parents.filter(
      (curItemParent, index) =>
        index === 0 || !curItemParent.cor.slice(0, -1).startsWith(parent.cor)
    );
    setParents(filteredParents);
  };

  const printElementAtEvent = (element) => {
    if (!element.length) return;

    const { index } = element[0];

    // console.log(
    //   currentData.labels[index],
    //   currentData.datasets[datasetIndex].data[index]
    // );
    return currentData.labels[index];
  };

  const getCorByDenumire = (clickedDenumireCor) => {
    return yourFunctions.find(
      (object) => object.denumireCor === clickedDenumireCor
    ).cor;
  };

  useEffect(() => {
    setSelectedOption(options[currentOptionIndex]);
  }, [options]);

  useEffect(() => {
    let newData = {
      labels: [],
      datasets: [
        {
          label: {},
          data: [],
          backgroundColor: [
            "rgb(255, 99, 132)",
            "rgb(54, 162, 235)",
            "rgb(255, 205, 86)",
          ],
          hoverOffset: 4,
        },
      ],
    };

    yourFunctions &&
      yourFunctions.forEach((pieElement) => {
        if (pieElement.parent === currentParent && !pieElement.isAllNull) {
          newData.labels.push(pieElement.denumireCor);
          newData.datasets[0].data.push(
            pieElement[`indicator${selectedOption?.value}`]
          );
        }
      });

    setCurrentData(newData);
  }, [yourFunctions, currentParent, selectedOption]);

  // console.log("renderizez chart cu :", currentData);

  return (
    <>
      <div>Selecteaza Indicator</div>
      <Dropdown
        menuClassName='active-scroll'
        controlClassName='cursor-pointer'
        options={options}
        value={selectedOption}
        onChange={(e) => {
          setCurrentOptionIndex(
            options.findIndex((obj) => obj.value === e.value)
          );
          setSelectedOption(e);
        }}
      />
      {/* <Select
        // placeholder={selectedOption.label}
        options={options}
        values={[selectedOption]}
        onChange={e => {
          setCurrentOptionIndex(options.findIndex(obj => obj.value === e[0].value));
          setSelectedOption(e[0])
        }}
      /> */}

      <div>{currentData.length}</div>
      <Doughnut
        ref={chartRef}
        data={currentData}
        onClick={handleDoughnutElementClick}
      />
      {parents.map((parent) => {
        return (
          <span
            className='inline-block cursor-pointer hover:scale-y-125 hover:text-gray'
            key={parent.cor}
            onClick={() => {
              handleBreadCrumbClick(parent);
            }}
          >
            {parent.denumireCor} /{" "}
          </span>
        );
      })}
    </>
  );
}

export default YourFunctionsChart;
