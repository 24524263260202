import { useAtom } from "jotai";
import state from "../state";
import { Navigate, Outlet } from "react-router-dom";

function PrivateRoute() {
  const [token] = useAtom(state.token);

  return token ? <Outlet /> : <Navigate to='/login' />;
}

export default PrivateRoute;
