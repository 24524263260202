import { useAtom } from "jotai";
import { Link } from "react-router-dom";
import homeCard1 from '../../images/cards/homeCard1.png'
import { userState } from "../../state";

function HomeCard({ path, title, text, icon, image }) {

  const [currentCompany] = useAtom(userState.currentCompany);

  return (
      <Link style={{pointerEvents: path === "/upload" && currentCompany.id > 1000000 ? 'none' : ''}} to={`${path}`}>
        <div style={{ height: "400px",  backgroundImage: `url(${image})`, width: "550px"}} className='pl-12 pr-28 py-8 shadow-lg relative w-full h-max'>
        <h3 className='text-primary2 text-3xl uppercase font-black'>{title}</h3>
        {/* <p className='mt-16 text-sm'></p> */}
        <ul className="leading-5 mt-20 list-disc">
          {text.split(".").map(row => <li className='leading-5 text-justify ml-4' style={{fontSize: "15px"}}>{row}</li>)}  
        </ul>
        
          <div className="absolute right-3 bottom-3">
            <span style={{color: '#dddfeb', fontSize: "120px"}} className="material-icons">{icon}</span>
          </div>
        </div>
      </Link>
  )
}

export default HomeCard;