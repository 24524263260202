import { useAtom } from "jotai";
import { yourFunctionsState } from "../../../state";
import { useEffect, useState } from 'react'

const FONTCOLORS=["Black","DarkMagenta","Green","Blue","Black"]

function YourFunctionsTable() {

  //lista de obiecte
  const [shownRows, setShownRows] = useState([])
  const [yourFunctions] = useAtom(yourFunctionsState.yourFunctions);
  const [yourFunctionsWithNull] = useAtom(yourFunctionsState.yourFunctionsWithNull)
  const [, setYourFunctionsGraphDate] = useAtom(yourFunctionsState.yourFunctionsGraphData)
  const [options] = useAtom(yourFunctionsState.columnNames);

  useEffect(()=>{setShownRows(yourFunctions);setYourFunctionsGraphDate(yourFunctions.filter(row=>row.nivel===1&&!row.isAllNull))},[yourFunctions])
  
  const handleClick = (currentRow) => {
    const result = [];
    
    shownRows.forEach(row =>{
      result.push(
        {...row,
        isExpanded: 
        row.nivel===1 //il afisam daca e primul nivel (intotdeauna deschis)
        || (!row.isExpanded&&(row.parent===currentRow.cor)) // daca este inchis dar este copil rowlui selcetat il afisam
        // || (row.isExpanded && (row.parent !== currentRow.cor)) // daca e deschis deja si nu este copil direct, il pastram afisat !
        || (row.isExpanded && (!row.parent.startsWith(currentRow.cor)))  // daca e deschis deja si nu este copil direct, il pastram afisat !
      // trebuie sa rezulte false daca este expandat si row.parent.
      // parintele lui incepe cu row.cor   
      })
    })

    const graphData= shownRows.filter(row=>row.parent===currentRow.cor&&!row.isAllNull);
    setYourFunctionsGraphDate(graphData)
    setShownRows(result)
    // console.log('--------+--------',shownRows)
  }

  return (
    <div>
      <table style={{ maxHeight: "69vh" }}
               className='text-left block active-scroll border-collapse leading-4 text-sm overflow-scroll'>
      
        <thead style={{ zIndex: '1' }} >
          <tr 
          style= {{textAlign: 'left', backgroundColor: 'gray', width: '100px'}} 
          className='h-12 border border-gray p-1 text-white'>
            <th>Nivel</th>
            <th>Cor</th>
            <th>Denumire Cor</th>
            <th>{options[0].label}</th>
            <th>{options[1].label}</th>
            <th>{options[2].label}</th>
            <th>{options[3].label}</th>
            <th>{options[4].label}</th>
          </tr>
        </thead>
        <tbody>
          {shownRows.map((currentRow, index) => {
            return (
              (currentRow.isExpanded
                 && (!currentRow.isAllNull || yourFunctionsWithNull)
                 ) &&
              <tr key={index} onClick={() => {handleClick(currentRow)}}
              style={{height: "48px", width: "100px", backgroundColor: currentRow.isAllNull?"white":"lightgray", fontWeight:"bold", textAlign:"center",color:FONTCOLORS[currentRow.nivel]}}
              className='p-1 border border-gray pr-8 relative'
              >
                <td>{currentRow?.nivel}</td>
                <td>{currentRow?.cor}</td>
                <td style={{textAlign:"left"}}>{currentRow?.denumireCor}</td>
                <td>{currentRow?.indicator1}</td>
                <td>{currentRow?.indicator2}</td>
                <td>{currentRow?.indicator3}</td>
                <td>{currentRow?.indicator4}</td>
                <td>{currentRow?.indicator5}</td>
              </tr>
            );
          })}

        </tbody>

      </table>
    </div >
  )
}

export default YourFunctionsTable;