import React, { useState } from "react";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Bar } from "react-chartjs-2";
import { Chart, registerables } from "chart.js";
import ModalComponent from "../ModalComponent";
Chart.register(...registerables);



function BenchBarChart({row,isDoubleColumn}) {
    ChartJS.register(ArcElement, Tooltip, Legend);

    const [modalIsOpen, setIsOpen] = useState(false);

  // const chartData = [];
  const months = [
    "jan",
    "feb",
    "mar",
    "apr",
    "may",
    "jun",
    "jul",
    "aug",
    "sep",
    "oct",
    "nov",
    "dec",
  ];
  let nutData = {
    labels: months,
    datasets: [
      {
        label:
          row.numeIndicator +
          (row.afisajDiviziune ? "  " + row.afisajDiviziune : ""),
        data: months.map((month) => (row[month] ? row[month] : 0)),
        backgroundColor: [row.culoareGrafic],
        borderColor: [row.culoareGrafic],
        barThickness: 40,
      },
    ],
  };
  if (isDoubleColumn) {nutData.datasets.push({
    label:
      row.numeIndicator +
      (row.afisajDiviziune ? "  " + row.afisajDiviziune : "")+" Piata",
    data: months.map((month,index) => (row[`bench_${index+1}`] ? row[`bench_${index+1}`] : 0)),
    backgroundColor: '#000000',
    borderColor: [row.culoareGrafic],
    pointRadius: 6,
    pointHoverRadius: 9,
  });}


  return (
    <div className='relative'>
      <Bar
        data={nutData}
        options={{ maintainAspectRatio: false }}
        style={{paddingRight: "50px"}}
        className='pl-8'
      />
      <div
        onClick={() => setIsOpen(true)}
        style={{ top: "20%", right: "6px", fontSize: "30px" }}
        className='absolute material-icons cursor-pointer hover:scale-150 transition-all'
      >
        zoom_in_map
      </div>
      <ModalComponent
        modalIsOpen={modalIsOpen}
        content={
          <>
            <div className='flex justify-between w-full text-3xl'>
              <p></p>
              <span
                onClick={() => setIsOpen(false)}
                className='material-icons cursor-pointer md-48 mt-4 mr-4'
              >
                cancel
              </span>
            </div>
            <Bar
              data={nutData}
            //   options={{ maintainAspectRatio: false }}
              className='pl-8 pr-10'
            />
          </>
        }
      />
    </div>
  );
}

export default BenchBarChart