export const JUDETE = [
  {
    "id_judet": 0,
    "judet": "Alege un judet..."
  },
  {
    "id_judet": 1,
    "judet": "JUDETUL ALBA"
  },
  {
    "id_judet": 2,
    "judet": "JUDETUL ARAD"
  },
  {
    "id_judet": 3,
    "judet": "JUDETUL ARGES"
  },
  {
    "id_judet": 4,
    "judet": "JUDETUL BACAU"
  },
  {
    "id_judet": 5,
    "judet": "JUDETUL BIHOR"
  },
  {
    "id_judet": 6,
    "judet": "JUDETUL BISTRITA-NASAUD"
  },
  {
    "id_judet": 7,
    "judet": "JUDETUL BOTOSANI"
  },
  {
    "id_judet": 8,
    "judet": "JUDETUL BRAILA"
  },
  {
    "id_judet": 9,
    "judet": "JUDETUL BRASOV"
  },
  {
    "id_judet": 10,
    "judet": "JUDETUL BUZAU"
  },
  {
    "id_judet": 11,
    "judet": "JUDETUL CALARASI"
  },
  {
    "id_judet": 12,
    "judet": "JUDETUL CARAS-SEVERIN"
  },
  {
    "id_judet": 13,
    "judet": "JUDETUL CLUJ"
  },
  {
    "id_judet": 14,
    "judet": "JUDETUL CONSTANTA"
  },
  {
    "id_judet": 15,
    "judet": "JUDETUL COVASNA"
  },
  {
    "id_judet": 16,
    "judet": "JUDETUL DAMBOVITA"
  },
  {
    "id_judet": 17,
    "judet": "JUDETUL DOLJ"
  },
  {
    "id_judet": 18,
    "judet": "JUDETUL GALATI"
  },
  {
    "id_judet": 19,
    "judet": "JUDETUL GIURGIU"
  },
  {
    "id_judet": 20,
    "judet": "JUDETUL GORJ"
  },
  {
    "id_judet": 21,
    "judet": "JUDETUL HARGHITA"
  },
  {
    "id_judet": 22,
    "judet": "JUDETUL HUNEDOARA"
  },
  {
    "id_judet": 23,
    "judet": "JUDETUL IALOMITA"
  },
  {
    "id_judet": 24,
    "judet": "JUDETUL IASI"
  },
  {
    "id_judet": 25,
    "judet": "JUDETUL ILFOV"
  },
  {
    "id_judet": 26,
    "judet": "JUDETUL MARAMURES"
  },
  {
    "id_judet": 27,
    "judet": "JUDETUL MEHEDINTI"
  },
  {
    "id_judet": 28,
    "judet": "JUDETUL MURES"
  },
  {
    "id_judet": 29,
    "judet": "JUDETUL NEAMT"
  },
  {
    "id_judet": 30,
    "judet": "JUDETUL OLT"
  },
  {
    "id_judet": 31,
    "judet": "JUDETUL PRAHOVA"
  },
  {
    "id_judet": 32,
    "judet": "JUDETUL SALAJ"
  },
  {
    "id_judet": 33,
    "judet": "JUDETUL SATU MARE"
  },
  {
    "id_judet": 34,
    "judet": "JUDETUL SIBIU"
  },
  {
    "id_judet": 35,
    "judet": "JUDETUL SUCEAVA"
  },
  {
    "id_judet": 36,
    "judet": "JUDETUL TELEORMAN"
  },
  {
    "id_judet": 37,
    "judet": "JUDETUL TIMIS"
  },
  {
    "id_judet": 38,
    "judet": "JUDETUL TULCEA"
  },
  {
    "id_judet": 39,
    "judet": "JUDETUL VALCEA"
  },
  {
    "id_judet": 40,
    "judet": "JUDETUL VASLUI"
  },
  {
    "id_judet": 41,
    "judet": "JUDETUL VRANCEA"
  },
  {
    "id_judet": 42,
    "judet": "MUNICIPIUL BUCURESTI"
  }
]