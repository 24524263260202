import React from "react";
import { useSearchParams } from "react-router-dom";
import useAxiosPrivate from "../hooks/useAxiosPrivate";
import { toast } from "react-toastify";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

function RegisterVerification() {
  const axiosPrivate = useAxiosPrivate();
  const navigate = useNavigate();

  const [searchParams] = useSearchParams();
  const code = searchParams.get("code");

  const sendVerificationCode = async () => {
      try {
    console.log(code);
      const response = await axiosPrivate.get(`/verify?code=${code}`);
      console.log(response.data);
      toast.success("Validare cu succes", { position: "top-center" });
    } catch (e) {
      console.log(e);
      toast.error("Cod invalid", { position: "top-center" });
    } finally {
      navigate("/login");
    }
  };

  useEffect(() => {
    sendVerificationCode();
  }, []);

  return <div></div>;
}

export default RegisterVerification;
