import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import "./index.css";
import MaterialIcon from "material-icons-react";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <>
      <App />
      <div className='hidden'>
        <MaterialIcon icon='person' />
      </div>
    </>
  </React.StrictMode>
);
