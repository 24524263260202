import * as yup from "yup";

const RegisterSchema = yup.object().shape({
    firstName: yup.string().max(20, "Must be 20 characters or less").required("Prenumele este necesar"),
    lastName: yup.string().max(20, "Must be 20 characters or less").required("Numele este necesar"),
    email: yup.string().email("Introdu o adresa valida de email").required("Emailul este necesar").matches(/^[a-zA-Z0-9_.+-]+@(?!(gmail|yahoo|hotmail)).*\.[a-zA-Z]{2,6}$/g, "Emailul trebuie sa fie de tip corporate"),
    password: yup.string().required('Parola este necesara').matches(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/, 'Parola trebuie sa contina cel putin opt caractere, o litera mare, o litera mica, o cifra si un caracter special'),
    confirmPassword: yup.string().required("Parilele nu se potrivesc")
        .oneOf([yup.ref('password'), null], 'Parolele nu se potrivesc'),
    termsConditions: yup.bool().oneOf([true], 'Accepta termenii si conditiile pentru a continua')
});


export default RegisterSchema;