import { useNavigate } from "react-router-dom";
import { useAtom } from "jotai";
import { userState, state } from "../../../state";
import LoginSchema from "../../../validations/LoginSchema";
import { Formik, Form } from "formik";
import TextFieldPlaceholder from "../../input-components/TextFieldPlaceholder";
import { toast } from "react-toastify";
import Api from "../../../utilities/Api";
import { useState } from "react";

function LoginForm({ setOnOtpPage, setUsedEmail, usedEmail }) {
  const [, setToken] = useAtom(state.token);
  const [pressedSubmit, setPressedSubmit] = useState(false);
  // const [isLoginDisabled, setIsLoginDisabled] = useState(false);

  const requestPasswordReset = async () => {
    const email = window.prompt("Te rog introdu adresa de email", usedEmail);
    if (email) {
      try {
        const response = await Api.post(`/reset-password`, { email: email });
        console.log(response);
        toast.success("Un email a fost trimis la adresa specificata", {
          position: "top-center",
        });
      } catch (e) {
        toast.success("Un email a fost trimis la adresa specificata", {
          position: "top-center",
        });
      }
    }
  };

  // let navigate = useNavigate();

  // const verifyOtp = (email) => {
  //   const otp = prompt("Please enter the OTP from the email");
  //   if (otp) {
  //     Api.get(`verify-otp?otp=${otp}&username=${email}`)
  //       .then((data) => {
  //         console.log(data);
  //         toast.success("Autentificare cu succes!", { position: "top-center" });
  //         setToken(data.data);
  //         navigate("/");
  //       })
  //       .catch((e) => {
  //         toast.error("Otp invalid!", { position: "top-center" });
  //         console.log(e);
  //       });
  //   }
  // };

  return (
    <Formik
      initialValues={{
        email: "",
        password: "",
      }}
      validationSchema={LoginSchema}
      onSubmit={(values) => {
        console.log(values);
        handleLogin(values);
      }}
    >
      {(formik) => {
        return (
          <Form className=''>
            <div>
              <TextFieldPlaceholder
                label='Username'
                name='email'
                type='email'
              />
            </div>
            <div>
              <TextFieldPlaceholder
                label='Parola'
                name='password'
                type='password'
              />
            </div>

            <div className='mt-4'>
              <button
                className='py-2 px-4  w-24 bg-primary2 text-white hover:brightness-125 block mx-auto'
                type='submit'
                disabled={pressedSubmit}
              >
                Log in
              </button>
            </div>

            <div className='flex justify-between mt-4'>
              <div>
                {/* <label className='inline mr-2 text-green' htmlFor='rememberMe'>
                  Memoreaza parola
                </label>
                <CheckboxField label='Memoreaza parola' name='rememberMe' /> */}
              </div>
              <p
                className='text-primary2 underline hover:cursor-pointer'
                onClick={requestPasswordReset}
              >
                Reseteaza parola
              </p>
            </div>
          </Form>
        );
      }}
    </Formik>
  );

  function handleLogin(values) {
    //pe login ii verific credentialele. Daca totul e ok, il trimit pe otp
    setPressedSubmit(true)
    Api.post("login", {
      username: values.email,
      password: values.password,
      rememberMe: values.rememberMe,
    })
      .then((data) => {
        console.log(data);
        toast.success("Otp a fost trimis pe email!", {
          position: "top-center",
        });
        setOnOtpPage(true);
        setUsedEmail(values.email);
      })
      .catch((e) => {
        console.log(e);
        setUsedEmail(values.email);
        toast.error("Credentiale invalide!", { position: "top-center" });
      }).finally(() => {
        setPressedSubmit(false);
      })
  }
}

export default LoginForm;
