import React from "react";
import Api from "../../../utilities/Api";
import { toast } from "react-toastify";
import state from "../../../state";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { useEffect } from "react";
import { useAtom } from "jotai";

function LoginOtp({ usedEmail, setOnOtpPage }) {
  let navigate = useNavigate();
  const [token, setToken] = useAtom(state.token);
  const [otp, setOtp] = useState("");
  const [isLoginDisabled, setIsLoginDisabled] = useState(false);

  const verifyOtp = () => {
    setIsLoginDisabled(true);
    Api.get(`verify-otp?otp=${otp}&username=${usedEmail}`)
      .then((data) => {
        console.log(data);
        toast.success("Autentificare cu succes!", { position: "top-center" });
        setToken(data.data);
        navigate("/");
      })
      .catch((e) => {
        toast.error("Otp invalid, incearca din nou!", {
          position: "top-center",
        });
        console.log(e);
        setOnOtpPage(false)
      })
      .finally(() => {
        setIsLoginDisabled(false);
      });
  };

  return (
    //todo sa iau otp-ul din form
    <div className='mt-12 h-52'>
      <input
        className='text-center text-black px-2 mb-4 border h-10'
        type='text'
        onChange={(e) => setOtp(e.target.value)}
      />
      <button
        style={{ cursor: isLoginDisabled ? "not-allowed" : "pointer" }}
        disabled={isLoginDisabled}
        onClick={verifyOtp}
        className='py-2 px-4  w-24 bg-primary2 text-white hover:brightness-125 block mx-auto mt-8'
      >
        Log in
      </button>
    </div>
  );
}

export default LoginOtp;
