import React from "react";
import TicketAdd from "./TicketAdd";
import TicketsList from "./TicketsList";
import { useAtom } from "jotai";
import { ticketsState, userState } from "../../../state";
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
import { useEffect } from "react";

function Tickets() {

  const [companyTickets, setCompanyTickets] = useAtom(ticketsState.companyTickets);
  const [company] = useAtom(userState.currentCompany);

  

  const axiosPrivate = useAxiosPrivate(); 

  useEffect(() => {
    getCompanyTickets();
  }, [company])

  const getCompanyTickets = async() => {
    try {
      const response = await axiosPrivate.get(`/company-tickets?cui=${company.cif}`);
      console.log(response.data);
      setCompanyTickets(response.data)
    } catch (e) {
      console.log(e)
    }
  }

  return (
    <div>
      <p>
        Lorem ipsum dolor sit amet consectetur adipisicing elit. Ducimus eveniet
        deserunt reiciendis officiis voluptatum obcaecati, officia totam impedit
        suscipit culpa!
      </p>
      <TicketAdd />
      <TicketsList />
      {/* //   todo:
    //   tickets add
    //   tickets view all    */}
    </div>
  );
}

// TODO
// 1. Sa apelez functia lu zemy care aduce toate tichetele asociate unei companii
// 2. Sa fac frontendul pt afiasre tichete
// 3. Cand da click pe un tichet, se apeleaza functia lui zemy care aduce detaliile tichet (conversatia)
// 4. De facut pe backend ce e necesar pt a face un flow de conversatie

export default Tickets;
