import React from "react";
import PricingFirstPage from "./PricingFirstPage";
import FirstPageInputs from "./FirstPageInputs";
import ContinueButtonFirstPage from "./ContinueButtonFirstPage";
import BackButton from "../common/BackButton";

function SubscriptionsFirstPageForm() {
  return (
    <div
      style={{ minWidth: "1200px" }}
      className='bg-white p-6 pr-24 shadow-2xl relative'
    >
      {/* Text  */}
      <div className='text-gray leading-5'>
        <div className='mr-72'>
          <h2 className="text-2xl font-bold">Abonament Promotional 1 an + 1 an gratuit</h2>
          <p>
            Activeaza abonamentul astazi si beneficiezi de urmatoarele
            functionalitati:
          </p>
          <ul className="mt-6">
            <li className='flex items-center gap-2'>
              <span className='material-icons'>check</span>
              <span>
                Indicatori calculati pe baza datelor din: Revisal &amp; D112
              </span>
            </li>
            <li className='flex items-center gap-2'>
              <span className='material-icons'>check</span>
              <span>
                Calculul si analiza evolutiei indicatorilor tat 2 ani (2022
                &amp; 2023) Nivel analiza: companie, rice nivel cor din
                companie, orice functie cor din companie*
              </span>
            </li>
            <li className='flex items-center gap-2'>
              <span className='material-icons'>check</span>
              <span>
                Acces la indicatorii benchmarking: 2 ani (2022 &amp; 2023)
                Comparatie cu: toata populatia, un anumit judet, o anumita
                sectiune caen
              </span>
            </li>
          </ul>
        </div>
        <div style={{ height: "2px", backgroundColor: "black", width: "85%" }} className='mt-4'></div>
        <div className="mr-72">
          <p>
            Note: pentru abonamentul multicompany indicator sunt disponibili si
            la nivel de grup <br /> <br />
            Abonamentul include o variatie de maxim 15% a numarului mediu de contracte active la finalul lunii de raportare calculat pe perioada de valabilitate a abonamentului
          </p>
        </div>
      </div>
      {/* Inputs  */}
      <div className="">
          <div>
        <FirstPageInputs />
        <PricingFirstPage />
        </div>
      </div>
      <BackButton gotoPage={1} />
      
    </div>
  );
}

export default SubscriptionsFirstPageForm;
