import { useAtom } from "jotai";
import { subscriptionState } from "../../../state";
import SelectedSubscriptionInfo from "../common/SelectedSubscriptionInfo";
import AddCompany from "./AddCompany";
import ContinueButtonSecondPage from "./ContinueButtonSecondPage";
import ShowCompanies from "./ShowCompanies";
import BackButton from "../common/BackButton";

function SubscriptionsSecondPageForm() {


  return (
    <div
      style={{ minWidth: "1200px" }}
      className='bg-white p-6 pr-24 shadow-2xl relative'
    >
      <SelectedSubscriptionInfo />
      <div className='flex mt-12 gap-8'>
        <AddCompany />
        <div style={{ width: "2px", backgroundColor: "gray" }}></div>
        <div className="flex-1">
          <ShowCompanies />
          <div>
            <p>
              Lorem ipsum dolor sit amet consectetur, adipisicing elit.
              Doloremque ut nihil doloribus, totam cupiditate tempore est
              numquam fuga accusamus facilis nam sit neque eum quam repellat
              enim rem ab, rerum dolore sed distinctio inventore quae odio.
              Animi facere provident illo.
            </p>
          </div>
        </div>
      </div>
      <div className='flex justify-end mt-2'>
        <ContinueButtonSecondPage />
      </div>
      <BackButton gotoPage={1} />
    </div>
  );
}

export default SubscriptionsSecondPageForm;
