import { userState, uploadPageState } from "../../state";
import { useAtom } from "jotai";
import { toast } from "react-toastify";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import ModalComponent from "../ModalComponent";
import { useState } from "react";


function UploadModalButton({ dateToSend, getCompanyRvsUploadDates, getCompanyD112UploadDates, getCompanyStatUploadDates, apiUrl, fileType }) {
  const [currentCompany] = useAtom(userState.currentCompany);
  const [user] = useAtom(userState.user);
  const [, setUploadingDates] = useAtom(uploadPageState.uploadingDates);
  const axiosPrivate = useAxiosPrivate();
  const [modalIsOpen, setIsOpen] = useState(false);
  const [errorText, setErrorText] = useState("");
  // console.log(errorText)
  // console.log(errorText.search(" :"));

  const handleReuploadClick = (e) => {
    console.log(document.querySelector("#upload-button"));
    if (e.target.checked) {
      document.querySelector("#upload-button").style.color = "gray";
      document.querySelector("#upload-button").style.cursor = "not-allowed";
    } else {
      document.querySelector("#upload-button").style.removeProperty("color");
      document.querySelector("#upload-button").style.removeProperty("cursor");
    }
  };

  const handleUploadClick = (e) => {
    if (e.target.parentElement.style.cursor != "not-allowed") {
      document.querySelector("#input-button").click();
    }
  };

  const handleSubmit = (e) => {
    let file = document.querySelector("#input-button").files[0];
    console.log(file);
    // aka a alaes varianta de a uploada fisier
    if (file) {
      document.querySelector("#file-name-text").innerHTML = file.name;
      sendFile(file);
    // aka a ales varianta de copy from last month 
    } else if (document.querySelector("#reupload-button").checked) {
      copyEntriesFromPreviousMonth();
    } else {
      toast.error("Te rugam sa selectezi o optiune", {position: "top-center"});
    }
  };

  const copyEntriesFromPreviousMonth = async () => {
    try {
        let headers = {
          "Content-Type": "multipart/form-data",
        };
          
      let bodyFormData = new FormData();
      bodyFormData.append("currentMonth", dateToSend);
      bodyFormData.append("previousMonth", subtractMonthFromDate(dateToSend));
      bodyFormData.append("companyCui", currentCompany.cif);
      bodyFormData.append("userId", user.id);

      toast.info("Procesul de copiere a inceput.", { position: "top-center" })
      const response = await axiosPrivate.post(
        "/api/copy-entries-prev-month",
        bodyFormData,
        headers
      );
      console.log(response);
      toast.success("Informatiile au fost copiate cu succes", { position: "top-center" });
      getCompanyRvsUploadDates();
    } catch (e) {
      console.log(e);
      if (e.response.status === 406) {
        toast.error("Nu exista inregistrari pentru luna precedenta", {position: "top-center"})
      } else {
        toast.error("A aparut o eroare", {position: "top-center"});
      }
    }
  };

  const subtractMonthFromDate = () => {
    let newDate = new Date(dateToSend);
    newDate.setMonth(newDate.getMonth() - 1);

    return newDate;
  };

  const sendFile = async (file) => {
    let bodyFormData = new FormData();
    bodyFormData.append("file", file);
    bodyFormData.append("companyId", currentCompany.cif);
    bodyFormData.append("currentMonth", dateToSend);
    bodyFormData.append("previousMonth", subtractMonthFromDate(dateToSend));
    bodyFormData.append("userId", user.id);

    let headers = {
      "Content-Type": "multipart/form-data",
    };

    try {
      toast.info("Fisierul uploadat este verificat si prelucrat. Procesul poate dura pana la 10 minute.", { position: "top-center" });
      setUploadingDates(oldValues => {
        oldValues.push({ "fileType": fileType, "date": dateToSend })
        return oldValues;
      })
      const response = await axiosPrivate.post(
        `${apiUrl}`,
        bodyFormData,
        headers
      );
      console.log(response);
      if (response.status === 208) {
        // const newDate =  dateToSend.getFullYear() + date.getMonth() + 1;
        const nextMonth = new Date(dateToSend);
        nextMonth.setMonth(nextMonth.getMonth() + 1);
        const nextMonthString =  nextMonth.toLocaleDateString('ro-RO', {year: 'numeric', month: 'long'});

        toast.info(`Atentie! Luna ${nextMonthString} a fost completata cu datele lunii pe care doriti sa o reincarcati; Pentru acuratete refaceti operatiunea si pentru luna ${nextMonthString}`, {position: "top-center"})
      }
      toast.success(
        "Fisierul dvs. a fost incarcat cu succes.", {position: "top-center"});
      if (fileType === "rvs") {
        getCompanyRvsUploadDates();
      } else if (fileType === "d112") {
        getCompanyD112UploadDates();
      } else if (fileType === "stat") {
        getCompanyStatUploadDates();
      }
    } catch (e) {
      console.log(e.response.status);
      if (fileType === "stat") {
        console.log(e.response.data);
        setErrorText(e.response.data);
        setIsOpen(true);
      } else {
        toast.error(e.response.data, { position: "top-center" });
      }
      
    } finally {
      setUploadingDates(oldValues => oldValues.filter(value => value.fileType !== fileType && value.date !== dateToSend))
    }
  };

  return (
    <div className='mt-40 flex gap-32 text-3xl'>
      <div>
        <div className='flex gap-4'>
          <div>
            <input
              id='input-button'
              onChange={(e) => {
                if (fileType == "rvs") {
                  document.querySelector("#reupload-button").disabled = true;
                  document.querySelector("#reupload-button").style.cursor =
                    "not-allowed";
                }

                document.querySelector("#file-name-text").innerHTML =
                  e.target.files[0].name;
              }}
              // accept='.rvs'
              type='file'
              accept={
                fileType === "rvs"
                  ? ".rvs"
                  : fileType === "stat"
                  ? ".xlsx"
                  : ".xml"
              }
              name=''
              className='hidden'
            />
            <div style={{ width: "40rem" }} className='border p-2'>
              <p
                className='overflow-hidden hover:overflow-visible'
                id='file-name-text'
              >
                Upload fisier
              </p>
            </div>
          </div>
          <div
            id='upload-button'
            className='relative cursor-pointer'
            onClick={handleUploadClick}
          >
            <span
              style={{ fontSize: "90px", top: "-24px" }}
              className='material-icons absolute left-0 md-48'
            >
              file_upload
            </span>
          </div>
        </div>
        {fileType === "rvs" && (
          <div className='text-right mt-8 relative'>
            <p>Raporteaza luna fara evenimente</p>
            <input
              id='reupload-button'
              onClick={handleReuploadClick}
              style={{ top: "0", right: "-65px" }}
              className='absolute w-10 h-10'
              type='checkbox'
            />
          </div>
        )}
      </div>

      <div className='text-white'>
        <button
          onClick={handleSubmit}
          className='bg-primary2 px-20 py-6 hover:brightness-150'
        >
          Submit
        </button>
      </div>
      <ModalComponent
        modalIsOpen={modalIsOpen}
        content={
          <>
            <div className='flex justify-between w-full text-3xl'>
              <p></p>
              <span
                onClick={() => setIsOpen(false)}
                className='material-icons cursor-pointer md-48 mt-4 mr-4'
              >
                cancel
              </span>
            </div>
            <div className="px-4">
              {errorText?.detalii_result?.split("\n").map(str => {
                return str.search("(Type)") !== -1 ? (<p className="mt-6"><b>{str}</b></p>) : (<p>{str}</p>);
              })}
            </div>
          </>
        }
      />
    </div>
  );
}

export default UploadModalButton;
