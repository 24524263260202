import React from "react";
import { useState } from "react";
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
import { fileToByteString } from "../../../utilities/FileHandler";
import { userState } from "../../../state";
import { useAtom } from "jotai";
import { ticketsState } from "../../../state";
import TicketDetailsConversation from "./TicketDetailsConversation";
import { toast } from "react-toastify";

import { useEffect } from "react";

const defaulValues = {
  message: "",
  closeTicket: ""
}

function UserTicketDetails({resetState}) {
  const axiosPrivate = useAxiosPrivate();

  const [toggleMessageBox, setToggleMessageBox] = useState(true);
  const [fileName, setFileName] = useState("");
  const [fileByteString, setFileByteString] = useState("");


  const [user] = useAtom(userState.user);

  const [currentTicketData] = useAtom(ticketsState.currentTicketData);
  const [currentTicketConversation] = useAtom(ticketsState.currentTicketConversation);

  const [formFields, setFormFields] = useState(defaulValues);

  const { message, closeTicket } = formFields;

  const handleChange = (e) => {
    const { name } = e.target;
    const value =
      e.target.type === "checkbox" ? e.target.checked : e.target.value;
    console.log(formFields);
    setFormFields({ ...formFields, [name]: value });
  };

  const imageToByteString = async () => {
    const fileName = document.getElementById("file-upload").files[0].name;
    let file = document.getElementById("file-upload").files[0];
    if (file.size > 5242880) {
      toast.warn("File size larger than 5 mb", { position: "top-center" });
      file = ""
      return;
    }
    const response = await fileToByteString(file);
    console.log(response);
    setFileName(fileName);
    setFileByteString(response);
  };


  const sendMessage = async() => {
    // trimit mesaul si fisierul spre backend in tichete activitati
    const ticketActivity = {
      idTichet: currentTicketData.idTichet,
      atasament: fileByteString,
      continut: message,
      numeFisier: fileName,
      idUser: user.id
    }

      try {
        //TODO daca se schimba id-ul pt 'inchis', trebuie sa modific din 0 in noul id!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
      const response = await axiosPrivate.post("/ticket-conversation", { ticketActivity, ticketStatus: closeTicket ? 0 : null })
      console.log(response.data);
    } catch (e) {
      console.log(e)
    } finally {
      resetState();
    }
  }


  return (
    <div style={{maxHeight: "800px"}} className='overflow-auto'>
      <div className="font-bold flex gap-6">
        <div>
          <p>Nr ticket</p>
          <div className='bg-lightGray p-2 pr-4 text-gray'>{currentTicketData.idTichet}</div>
        </div>
        <div>
          <p>Categorie</p>
          <div className='bg-lightGray p-2 pr-4 text-gray'>{currentTicketData.categorie}</div>
        </div>
        
        <div className="text-center">
          <p>Adauga raspuns</p>
          <p style={{fontSize: "50px"}} className="material-icons cursor-pointer" onClick={() => setToggleMessageBox(!toggleMessageBox)}>add_box</p>
        </div>
        <div className="text-center">
          <p>Inchide ticket</p>
          <input style={{width: "39px", height: "39px"}} type="checkbox" name="closeTicket" id="closeTicket" checked={closeTicket} onChange={handleChange} />
        </div>
        <div>
          <p>Status</p>
          <div className='bg-lightGray p-2 pr-4 text-gray'>{currentTicketData.status}</div>
        </div>
        <div className="mt-6">
          <div className='bg-primary2 text-white p-2 pr-8 cursor-pointer h-10' onClick={sendMessage}>Salveaza</div>
        </div>
          </div>
          <div>
          <p>Subiect</p>
          <div className='bg-lightGray p-2 pr-4 max-w-md h-10 overflow-auto text-gray'>{currentTicketData.subiect}</div>
        </div>

      <div>
        {toggleMessageBox &&
          <div>
            <textarea onChange={handleChange} className="border-2 mt-4" name="message" value={message} id="message" cols="60" rows="5"></textarea>
            <br />
            <span className="material-icons mt-2 md-48 cursor-pointer overflow-auto h-12" onClick={() => document.getElementById("file-upload").click()}>attach_file</span>
            <input className="hidden" type="file" accept="image/png, image/jpeg, image/jpg," name="" id="file-upload" onChange={imageToByteString} />
        </div>
        }
      </div>
      <TicketDetailsConversation />
    </div>
  );
}


export default UserTicketDetails