import React from "react";
import image from "../../images/cards/homeCard4.png";

function SupportCard() {
  return (
    <div
      style={{
        height: "400px",
        width: "550px",
        zIndex: "1",
        backgroundImage: `url(${image})`,
      }}
      className='p-8 bg-white shadow-lg text-xl border border-lightGray font-bold'
    >
      <h3 className='text-primary2 mb-12 text-3xl'>
        {new Date().toLocaleDateString("ro-RO")}
          </h3>
          <h3 className="mt-24 text-primary2 font-black">SUPORT</h3>
    </div>
  );
}

export default SupportCard;
