import { useEffect, useState } from "react";
import { useAtom } from "jotai";
import axios from "axios";
import { uploadPageState } from "../../state";
import { axiosPrivate } from "../../utilities/Api";

const romanianMonths = ['Ianuarie', 'Februarie', 'Martie', 'Aprilie', 'Mai', 'Iunie', 'Iulie', 'August', 'Septembrie', 'Octombrie', 'Noiembrie', 'Decembrie'];

const today = Date.now();
const status = {
  late : {statusIconColor: "#fc0404", buttonIconColor: "#00b050"},
  uploaded: {statusIconColor: "#00b050", buttonIconColor: "#c5e0b3"},
  notYet: {statusIconColor: "#c0bcbc", buttonIconColor: "#c0bcbc"},
}




function UploadMonthCardComponent({ date, companyUploadDates, showModalWithContent, cardType, companyStatModel }) {

  const [isCopied, setIsCopied] = useState(false);

  const [currentStatus, setCurrentStatus] = useState(status.notYet)
  const [uploadedDate, setUploadedDate] = useState("");
  const [uploadingDates] = useAtom(uploadPageState.uploadingDates);


  
  const getButtonType = () => {
    if (currentStatus.buttonIconColor === "#c5e0b3") {
      return "reUpload";
    } else if (currentStatus.buttonIconColor === "#00b050") {
      return "upload";
    }
    return "incative";
  }

 


  const labelMonth = romanianMonths[date.getMonth()] + " " + date.getFullYear()
  let datePlanified = new Date(date.getFullYear(), date.getMonth() + 1, 1);
  //TODO ma folosesc de date planified ca sa fac un 'currentstatus' ca variabila const curStatus = late, notYet sau uploaded etc.
  // il trimit pe el in setlatesttask, pt ca in teorie nu ar trebui sa se modifice nici pe update, deci in teorie, ar trebui sa mearga :)


  const updateStatus = () => {
    const foundDateObject = companyUploadDates.find(uploadDate => (uploadDate.perioada.getFullYear() === date.getFullYear() && uploadDate.perioada.getMonth() === date.getMonth()))
    
    if (foundDateObject) {
      if (companyUploadDates.find(uploadDate => uploadDate.copied === "C" && (uploadDate.perioada.getFullYear() === date.getFullYear() && uploadDate.perioada.getMonth() === date.getMonth()))) { 
        setIsCopied(true);
      } 
      setCurrentStatus(status.uploaded);
      setUploadedDate(foundDateObject.timeStamp);
    } else if (today > datePlanified) {
      setCurrentStatus(status.late);
    } else if (today < datePlanified) {
      setCurrentStatus(status.notYet);
    }
    if (cardType === "stat" && !companyStatModel) {
        setCurrentStatus(status.notYet);
    }
    console.log(uploadingDates)
    console.log(cardType, date);
    const lalal = uploadingDates.find(values => values.fileType === cardType && values.date.getTime() === date.getTime());
    console.log(lalal);
    if (lalal) {
      setCurrentStatus(status.notYet);
    }
  }

  

  useEffect(() => {
    updateStatus();
    getButtonType();
  }, [date])
  


  const statusColor = currentStatus.statusIconColor;
  const buttonColor = currentStatus.buttonIconColor;
  const datePlanifiedAsString = (cardType === "rvs" ? datePlanified.getDate() : parseInt(datePlanified.getDate() + 24)) + "/" + (datePlanified.getMonth() + 1) + "/" + datePlanified.getFullYear() ;
  const buttonType = getButtonType();
  const uploadDateString = new Date(uploadedDate).toLocaleDateString("ro-RO", { day: 'numeric', month: 'short', year: 'numeric', hour: '2-digit', minute:'2-digit' });

  

  return (
    <div className='flex gap-4 mb-1 bg-white'>
      {cardType === "rvs" && <div style={{backgroundColor: statusColor, color: '#f0eded', width: "160px"}} className='pt-3 pl-2 mb-2'>{labelMonth}</div>}
      <div style={{width: "183px"}} className='bg-white text-gray p-3 mb-2 border text-sm'>
        <p style={{color: statusColor}}>{currentStatus !== status.uploaded ? `Planificat: ${datePlanifiedAsString}` : isCopied ? `(C): ${uploadDateString}` : `${uploadDateString}` }</p>
      </div>
      <div className='flex gap-10'>
        {/* <span className="w-10">
          <span style={{ color: statusColor }}  className="material-icons md-48">pending_actions</span>
        </span> */}
        <span style={{ cursor: buttonColor === "#c0bcbc" ? "default" : "pointer" }} onClick={() => (cardType !== "stat" || companyStatModel) && showModalWithContent(buttonType, date, cardType)}>
          <span style={{color: buttonColor}} className="material-icons md-48 hover:brightness-75">file_upload</span>
        </span>
        
      </div>
    </div>
  );
}

export default UploadMonthCardComponent;
