import { toast } from "react-toastify";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import { useAtom } from "jotai";
import { userState } from "../../state";

function CnpExceptionsShow({ description, id, getCnpExceptions }) {
    
    const axiosPrivate = useAxiosPrivate();
  const [user, setUser] = useAtom(userState.user);
  const [currentComany] = useAtom(userState.currentCompany);

    const removeException = async () => {
        try {
            const response = await axiosPrivate.delete(`/api/cnp-exception/${id}/${user.id}/${currentComany.id}`);
            toast.success("Exceptia a fost stearsa cu succes", {
              position: "top-center",
            });
            getCnpExceptions();
        } catch (e) {
            console.log(e);
            toast.error("A aparut o eroare, incearca mai tarziu", {
              position: "top-center",
            });
        }
    }

  return (
    <div className='flex gap-4'>
      <div style={{width: "500px"}} className='mt-4 border-2 h-12 p-3'>
        <p>{description}</p>
      </div>
      <div className='flex items-end'>
        <span onClick={removeException} className='material-icons md-48 cursor-pointer'>remove</span>
      </div>
    </div>
  );
}

export default CnpExceptionsShow;
